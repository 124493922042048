import React, { useEffect } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ContactSection from './contactSection.js';
import HireSection from './hireSection.js'
import HeroSection from './heroSection.js'
import Testimonials from './testimonials.js';
import Customers from './customers.js';

export const Homepage = () => {

    useEffect(() => {
        const handleScroll = () => {
            const elements = document.querySelectorAll('.div-container');
            elements.forEach((element) => {
                const positionFromTop = element.getBoundingClientRect().top;
                const threshold = window.innerHeight - 100;
                console.log(positionFromTop, threshold)
                if (positionFromTop < threshold) {
                    element.classList.add('visible');
                } else {
                    element.classList.remove('visible');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        document.title = 'Software Development Consultancy in Harlow, Essex | Mobyte';

        // Create meta description
        const metaDescription = document.createElement('meta');
        metaDescription.setAttribute('name', 'description');
        metaDescription.content = 'Professional software services provider that specializes in delivering fully managed solutions based on Microsoft .NET, React and JavaScript.';

        // Create meta keywords
        const metaKeywords = document.createElement('meta');
        metaKeywords.setAttribute('name', 'keywords');
        metaKeywords.content = 'Software development, web design, data integration, software modernization, database management, automation, business applications, responsive websites, data consolidation, technology updates, process automation';

        const metaRobots = document.createElement('meta');
        metaRobots.setAttribute('name', 'robots');
        metaRobots.content = 'index';
        document.head.appendChild(metaRobots);

        // Append both meta elements to the head
        document.head.appendChild(metaDescription);
        document.head.appendChild(metaKeywords);
        document.head.appendChild(metaRobots);

        return () => {
            // Clean up added meta tags when component unmounts
            document.head.removeChild(metaDescription);
            document.head.removeChild(metaKeywords);
            document.head.removeChild(metaRobots);

        };
    }, []);

    // const subheading = (
    //     <span className="hero-text">
    //         {/* Unlock Your Potential with <span className="emph-brush accent-1-text">Custom IT Solutions</span> */}
    //         <h1> Driving growth together with <span className="emphasis-text"> <br />custom, scalable IT solutions</span></h1>
    //     </span >
    // )

    return (
        <>
            <HeroSection />
            {/* <div className="" >
                <TextOnImage imageUrl={image9} heading="Book your FREE consultation now and discover the IT systems that will drive your growth."
                    subheading={subheading} fromHome={true} altText={"Laptop displaying interactive dashboard"} />
                <div className="icon-background">
                </div>
            </div> */}

            <div className="contact-section">
                <ContactSection />
            </div>
            {/* <div className="statement-section ">
                <StatementSection />
            </div> */}
            {/* <div className="client-section">
                <ClientSection />
            </div> */}
            {/* <div className="stats-section">
                <StatsSection />
            </div> */}
            <div className="hire-section">
                <HireSection />
            </div>
            <div className="stats-section">
                <Customers />
            </div>

            <section className="testimonial-section">
                <Testimonials />
            </section>


        </>
    );
}

export default Homepage
