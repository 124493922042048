const codeLines = [
    [
        { text: 'const ', className: 'keyword' },
        { text: 'buildSolution', className: 'function' },
        { text: ' = () => {', className: 'operator' },
    ],
    [
        { text: '  const ', className: 'keyword' },
        { text: 'design', className: 'variable' },
        { text: ' = ', className: 'operator' },
        { text: '"scalable";', className: 'string' },
    ],
    [
        { text: '  const ', className: 'keyword' },
        { text: 'optimize', className: 'function' },
        { text: ' = (value) => {', className: 'operator' },
    ],
    [
        { text: '    if ', className: 'keyword' },
        { text: '(value === ', className: 'variable' },
        { text: '"scalable")', className: 'string' },
    ],
    [
        { text: '      return ', className: 'keyword' },
        { text: '"efficiency + innovation";', className: 'string' },
    ],
    [
        { text: '  };', className: 'operator' },
    ],
    [
        { text: '  const ', className: 'keyword' },
        { text: 'growth', className: 'variable' },
        { text: ' = ', className: 'operator' },
        { text: 'optimize(design);', className: 'function' },
    ],
    [
        { text: '  return ', className: 'keyword' },
        { text: 'growth', className: 'variable' },
        { text: ';', className: 'operator' },
    ],
    [
        { text: '};', className: 'operator' },
    ],
    [
        { text: 'export default ', className: 'keyword' },
        { text: 'buildSolution;', className: 'function' },
    ],
];

export default codeLines;