import React, { useState } from 'react';
import { AiOutlineCode, AiOutlineCopy, AiOutlineDownload } from 'react-icons/ai';
import { Button, Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap';

function CodeGenerator() {


    const [sqlScript, setSqlScript] = useState('');
    const [generatedCSharpModel, setGeneratedCSharpModel] = useState('');
    const [generatedCSharpMethods, setGeneratedCSharpMethods] = useState('');
    const [generatedReactLanding, setGeneratedReactLanding] = useState('');
    const [generatedReactColumns, setGeneratedReactColumns] = useState('');
    const [generatedReactEdit, setGeneratedReactEdit] = useState('');
    const [generatedJSON, setGeneratedJSON] = useState('');
    const [tableFile, setTableFile] = useState('');
    const [tableToUpdate, setTableToUpdate] = useState('');

    const tableSQL = `SELECT
    t.name AS ObjectName,
    c.name  AS fieldName,
    CASE
        WHEN c.system_type_id IN (56) THEN left('int'+replicate(' ',10),10)
        WHEN c.system_type_id IN (61) THEN left('datetime'+replicate(' ',10),10)
         WHEN c.system_type_id IN (239) THEN left('nchar'+replicate(' ',10),10)
         WHEN c.system_type_id IN (231) THEN left('nvarchar'+replicate(' ',10),10)
         WHEN c.system_type_id IN (167) THEN left('varchar'+replicate(' ',10),10)
        WHEN c.system_type_id IN (173, 175) THEN left('binary'+replicate(' ',10),10)
        WHEN c.system_type_id IN (106) THEN left('decimal'+replicate(' ',10),10)
        ELSE  ltrim(c.system_type_id)
    END AS fieldType,
    CASE
        WHEN c.max_length = -1 THEN 'MAX'
        ELSE CAST(c.max_length AS VARCHAR) 
    END AS fieldLength,
	replace(c.name,'_',' ') AS fieldDescription,
    CASE
        WHEN lower(c.name) IN ('id','sysid','rel_rec','sys_id') THEN 'H'
        WHEN lower(c.name) IN ('up_date') THEN 'V;100;YMDHMS'
        WHEN lower(c.name) IN ('up_user') THEN 'V;100'
	    WHEN c.system_type_id IN (61) THEN 'V;100;YMD'
		else 'V;150;text' END AS formatLanding,
    CASE
        WHEN lower(c.name) IN ('id','sysid','rel_rec','sys_id') THEN 'H'
        WHEN lower(c.name) IN ('up_date') THEN 'D;4;YMDHMS;text'
        WHEN lower(c.name) IN ('up_user') THEN 'D;4;string;text'
	    WHEN c.system_type_id IN (61) THEN 'V;4;YMD;text'
		else 'V;4;string;text' END AS formatModal,
		CASE
        WHEN lower(c.name) IN ('rel_rec') THEN 'R'
		WHEN lower(c.name) IN ('sys_id','sysid') THEN 'S'
        WHEN lower(c.name) IN ('id') THEN 'Y'
        WHEN lower(c.name) IN ('up_date','updated_on') THEN 'UO'
        WHEN lower(c.name) IN ('up_user','updated_by') THEN 'UB'
		WHEN lower(c.name) IN ('cr_date','created_on') THEN 'CO'
        WHEN lower(c.name) IN ('cr_user','created_by') THEN 'CB'
		else 'N' END AS fieldKey
FROM sys.tables t
JOIN sys.columns c ON t.object_id = c.object_id
where t.name='tableName'
ORDER BY ObjectName, c.column_id;
`
    const viewSQL = `SELECT 
    v.name AS ObjectName,
    c.name  AS fieldName,
    CASE
        WHEN c.system_type_id IN (56) THEN  'int' 
        WHEN c.system_type_id IN (61) THEN  'datetime' 
        WHEN c.system_type_id IN (239) THEN  'nchar' 
        WHEN c.system_type_id IN (231) THEN  'nvarchar' 
        WHEN c.system_type_id IN (167) THEN  'varchar' 
        WHEN c.system_type_id IN (173, 175) THEN  'binary' 
        WHEN c.system_type_id IN (106) THEN  'decimal' 
        ELSE  ltrim(c.system_type_id)
    END AS fieldType,
    CASE
        WHEN t.name IN('varchar', 'nvarchar', 'char', 'nchar') THEN   ltrim(c.max_length) 
        WHEN t.name IN('datetime', 'date') THEN  ''
        ELSE ''
    END AS fieldLength,
	replace(c.name,'_',' ') AS fieldDescription,
    CASE
        WHEN lower(c.name) IN ('id','sysid','rel_rec') THEN 'H'
        WHEN lower(c.name) IN ('up_date') THEN 'V;100;YMDHMS'
        WHEN lower(c.name) IN ('up_user') THEN 'V;100;text'
	    WHEN c.system_type_id IN (61) THEN 'V;100;YMD'
		else 'V;150;text' 
	END AS formatLanding,
    CASE
        WHEN lower(c.name) IN ('id','sysid','rel_rec','sys_id') THEN 'H'
        WHEN lower(c.name) IN ('up_date') THEN 'D;4;YMDHMS;text'
        WHEN lower(c.name) IN ('up_user') THEN 'D;4;string;text'
	    WHEN c.system_type_id IN (61) THEN 'V;4;YMD;text'
		else 'V;4;string;text' 
	END AS formatModal,
	CASE
        WHEN lower(c.name) IN ('rel_rec') THEN 'R'
		WHEN lower(c.name) IN ('sys_id','sysid') THEN 'S'
        WHEN lower(c.name) IN ('id') THEN 'Y'
        WHEN lower(c.name) IN ('up_date','updated_on') THEN 'UO'
        WHEN lower(c.name) IN ('up_user','updated_by') THEN 'UB'
		WHEN lower(c.name) IN ('cr_date','created_on') THEN 'CO'
        WHEN lower(c.name) IN ('cr_user','created_by') THEN 'CB'
		else 'N' END AS fieldKey
FROM sys.views v
INNER JOIN sys.columns c ON v.object_id = c.object_id
LEFT JOIN sys.types t ON c.user_type_id = t.user_type_id
LEFT JOIN sys.objects b ON v.parent_object_id = b.object_id
where v.name = 'viewName'
ORDER BY v.name, c.column_id;`


    const copyToClipboard = (stateValue) => {
        navigator.clipboard.writeText(stateValue)
    };

    const handleChange = (e) => {
        setSqlScript(e.target.value);
        if (tableToUpdate === null || tableToUpdate === '') {

            var lines = e.target.value.split('\n');
            for (const line of lines) {
                if (line.includes('\t')) {
                    var parts = line.split('\t');
                    console.log(line, parts.length)

                    if (parts.length === 8) {
                        var tableNameMatch = parts[0];
                        console.log(tableNameMatch)
                        setTableToUpdate(tableNameMatch);
                        return;
                    }
                }
            }
        }
    }



    const generateCode = () => {
        if (!sqlScript) {
            return; // Skip if the input text area is empty
        }
        try {

            // Split SQL script into lines
            var lines = sqlScript.split('\n');

            // Extract table name and field definitions
            let tableName = '';
            let keyField = '';
            let keyFieldType = '';
            var fieldDefinitions = [];
            for (const line of lines) {
                if (line.includes('\t')) {
                    var parts = line.split('\t');
                    if (parts.length === 8) {
                        var tableNameMatch = parts[0];
                        var fieldName = parts[1].trim();
                        var fieldType = parts[2].trim();
                        var fieldTypeSQL = sqlToCSharpType(fieldType);
                        var fieldLength = parts[3];
                        var fieldDescription = parts[4].trim();
                        var formatLanding = parts[5] // Visible/Hidden(V/H/M);Size;Format   e.g. V;200;DMY
                        var formatModal = parts[6];  // Visible/Hidden/Disbaled/Mandatory(V/H/D/M);Size (Bootstrap Col);Format;Type   e.g. V;6;DMYHMS;TEXTAREA
                        var fieldKey = parts[7]; // Y/N
                        if (parts[7] === "Y") {
                            keyField = parts[1].trim().toUpperCase();
                            keyFieldType = sqlToCSharpType(fieldType)
                        }
                        var lowerCaseFieldName = fieldName.charAt(0).toLowerCase() + fieldName.slice(1);
                        tableName = tableNameMatch;
                        fieldDefinitions.push({ fieldName, fieldType, fieldTypeSQL, lowerCaseFieldName, fieldLength, fieldDescription, formatLanding, formatModal, fieldKey });
                    }
                }
            }

            var fieldsToIgnore = [keyField, "UP_DATE", "UP_USER", "REL_REC", "SYSID", "SYS_ID", "UPDATED_BY", "UPDATED_ON", "CREATED_ON", "CREATED_BY", "ID"]
            var formFieldsToIgnore = ["REL_REC", "SYSID", "SYS_ID", "ID"]
            var jsonCode = `select '{'`
            for ({ fieldName, fieldTypeSQL } of fieldDefinitions) {

                if (!fieldsToIgnore.includes(fieldName.toLocaleUpperCase())) {
                    // console.log(fieldTypeSQL)
                    switch (fieldTypeSQL) {
                        case "string":
                            jsonCode += `union all select top 1 '"${fieldName}":' + isnull('"' + ltrim(${fieldName}) + '"','null') + ',' from [${tableName}] \n`;
                            break;
                        case "DateTime":
                            jsonCode += `union all select top 1 '"${fieldName}":' + isnull('"' + FORMAT(${fieldName}, 'yyyy-MM-dd hh:mm:ss') + '"','null') + ',' from [${tableName}] \n`;
                            break;
                        default:
                            jsonCode += `union all select top 1 '"${fieldName}":' + isnull(ltrim(${fieldName}),'null') + ',' from [${tableName}] \n`;
                            break;
                    }

                    // if (fieldTypeSQL === "string" || fieldTypeSQL === "DateTime") {
                    //     // jsonCode += `union all select top 1 '"${fieldName}":' + CASE WHEN ${fieldName} IS NULL THEN 'null' ELSE '"' + ltrim(${fieldName})+'"' END + ',' from [${tableName}] \n`;
                    //     jsonCode += `union all select top 1 '"${fieldName}":' + isnull('"' + ltrim(${fieldName}) + '"','null') + ',' from [${tableName}] \n`;
                    // }
                    // else {
                    //     jsonCode += `union all select top 1 '"${fieldName}":' + CASE WHEN ${fieldName} IS NULL THEN 'null' ELSE ltrim(${fieldName}) END +',' from [${tableName}] \n`;
                    // }
                }
            }
            jsonCode += `union all select '}'`

            // Generate C# model code
            var cSharpModelCode = ""
            if (keyField === '') {
                cSharpModelCode += "[Keyless]\n";
            }
            cSharpModelCode += `public class ${tableName}\n{\n`;
            for ({ fieldName, fieldTypeSQL } of fieldDefinitions) {
                var cSharpType = fieldTypeSQL;
                if (keyField.toUpperCase() !== "ID" && keyField.toUpperCase() === fieldName.toUpperCase()) {
                    cSharpModelCode += `[Key]\n`
                }
                cSharpModelCode += `    public ${(cSharpType === "string" ? cSharpType : cSharpType + "?")
                    } ${fieldName} { get; set; } \n`;
            }
            cSharpModelCode += '}\n';
            // Split the table name by '_' and use the last part for the API name
            const tableNameParts = tableName.split('_');
            const apiName = tableNameParts[tableNameParts.length - 1].toLowerCase();
            const componentName = tableNameParts[tableNameParts.length - 1];
            setTableFile(componentName)
            // Generate C# method code
            const cSharpMethodCode = `
            
            [HttpGet("qry")]
public IActionResult Qry${apiName} ()
{
        var data = _repository.${tableName}.OrderByDescending(d => d.up_date);
        return Json(data);
    }


    [HttpGet("qry/{${keyField}}")]
public IActionResult QrySingle${apiName} (${keyFieldType} ${keyField})
    {
        var data = _repository.${tableName}.Where(d => d.${keyField} == ${keyField});
        return Json(data);
    }

    [HttpPut("upd/{${keyField}}")]
public IActionResult Upd${apiName} (${keyFieldType} ${keyField}, [FromBody] ${tableName} ${apiName.toLowerCase()})
    {
    tblUser currentUser = new UserController(_repository, _httpContextAccessor).User_Get();
        var recordToUpdate = _repository.${tableName}.FirstOrDefault(d => d.${keyField} == ${keyField});
    ${fieldDefinitions
                    .filter(field => !fieldsToIgnore.includes(field.fieldName.toUpperCase()))
                    .map(field => `recordToUpdate.${field.fieldName} = ${apiName.toLowerCase()}.${field.fieldName};`).join('\n    ')
                }
     ${fieldDefinitions
                    .filter(field => fieldsToIgnore.includes(field.fieldName.toUpperCase()))
                    .map((field) => {
                        switch (field.fieldKey) {
                            case "UO":
                                return `recordToUpdate.${field.fieldName} = DateTime.Now;`
                            case "UB":
                                return `recordToUpdate.${field.fieldName} = currentUser.UserID;`
                            default:
                                return ""
                        }

                    }
                    )
                    .filter(line => line !== "")
                    .join('\n')}
            _repository.${tableName}.Update(recordToUpdate);
            _repository.SaveChanges();
            return Json(recordToUpdate);
        }

    [HttpPut("del/{${keyField}}")]
public IActionResult Delete${apiName} (${keyFieldType} ${keyField}, [FromBody] ${tableName} ${apiName.toLowerCase()})
    {
    tblUser currentUser = new UserController(_repository, _httpContextAccessor).User_Get();
        var recordToUpdate = _repository.${tableName}.FirstOrDefault(d => d.${keyField} == ${keyField});
 
     ${fieldDefinitions
                    .filter(field => fieldsToIgnore.includes(field.fieldName.toUpperCase()))
                    .map((field) => {
                        switch (field.fieldKey) {
                            case "UO":
                                return `recordToUpdate.${field.fieldName} = DateTime.Now;`
                            case "UB":
                                return `recordToUpdate.${field.fieldName} = currentUser.UserID;`
                            case "R":
                                return `recordToUpdate.${field.fieldName} = 0;`

                            default:
                                return ""
                        }

                    }
                    )
                    .filter(line => line !== "")
                    .join('\n')}
            _repository.${tableName}.Update(recordToUpdate);
            _repository.SaveChanges();
            return Json(recordToUpdate);
        }

[HttpPost("add")]
public IActionResult Add${apiName} ([FromBody] List <${tableName}> ${apiName.toLowerCase()})
    {
    tblUser currentUser = new UserController(_repository, _httpContextAccessor).User_Get();
    ${fieldDefinitions
                    .filter(field => fieldsToIgnore.includes(field.fieldName.toUpperCase()))
                    .map((field) => {
                        switch (field.fieldKey) {
                            case "UO":
                            case "CO":
                                return `${apiName.toLowerCase()}.ForEach.(d => d.${field.fieldName} = DateTime.Now);`
                            case "UB":
                            case "CB":
                                return `${apiName.toLowerCase()}.ForEach.(d => d.${field.fieldName} = currentUser.UserID);`
                            case "R":
                            case "S":
                                return `${apiName.toLowerCase()}.ForEach.(d => d.${field.fieldName} = 1);`
                            default:
                                return ""
                        }
                    }
                    )
                    .filter(line => line !== "")
                    .join('\n')}    
        _repository.${tableName}.AddRange(${apiName});
        _repository.SaveChanges();
        return Json(${apiName});
    }
    `;



            const reactLanding = `
    import React from 'react';
    import { useState, useCallback, useEffect } from "react";
    import { Row, Col, Button } from 'reactstrap'
    import DataTable from 'react-data-table-component';
    import ${componentName}Columns from './ ${componentName}Columns';
    import ${componentName}Modal from './ ${componentName}Modal'
    import base from '../services/baseService';
    import TableStyles from '../shared/TableStyles';
    import LoadingSpinner from '../shared/LoadingSpinner';
    import '../assets/css/common.css'
    import { Edit24 } from '@bphxd/ds-core-react/lib/icons';
    import TitleBanner from '../CardManager/TitleBanner'

    const xRowsPerPage = false

    const ${componentName} = () => {

        const [data, setData] = useState([]);
        const [originData, setOriginData] = useState([])
        const [searchLoading, setSearchLoading] = useState(true);
        const [showXSFilters, setShowXSFilters] = useState(false)
        const [loading, setLoading] = useState(true);
        const [showDetailModal, setShowDetailModal] = useState(false);
        const [page, setPage] = useState("");
        const Page = " ${apiName}"
        const [ID, setID] = useState("")
        const [columns, setColumns] = useState([])

        useEffect(() => {
            function handleResize() {
                if (window.innerWidth > 1400) {
                    setShowXSFilters(false)
                }
            }
            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }, []);



        // get data 
        const get${componentName} = () => {
            setSearchLoading(true)
            base.getAll("${apiName}/qry").then((res) => {
                setOriginData(res)
                setData(res)
                setTimeout(() => {
                    setSearchLoading(false);
                    setLoading(false);
                }, 0);
            });
        }

        //open edit form
        const edit${componentName} = useCallback((state) => {
            var id = state.target.id
            if (id === "") {
                id = state.target.nearestViewportElement.id
            }
            var cID = id.substring(0, 2)
            setID(cID)
            setShowDetailModal(true)
            setPage("edit");
        }, []);

        // reload data after add 
        const refreshData = () => {
            setTimeout(() => {
            get${componentName} ()
            }, 200);
        }

        const create${componentName} = () => {
            setShowDetailModal(true)
            setPage("create");
        }

        const closeModal = () => {
            setShowDetailModal(false)
            setID("")

        }

        // add edit button on grid and get data
        useEffect(() => {
            var btnColumn = {}
            btnColumn = {
                name: '',
                sortable: false,
                ignoreRowClick: true,
                center: true,
                minWidth: '50px',
                maxWidth: '50px',

                cell: row =>
                    <>
                        <div id={row.id + "div"} onClick={edit${componentName}}><div id={row.id + "btn"} className="no-bg m-3" ><Edit24 id={row.id} /></div></div >
                </>,
        wrap: false,

        }

setColumns((cols) => {
    return [btnColumn, ...${componentName}Columns]
})
        get${componentName} ();

    }, [edit${componentName}])

return (
    <>
        <TitleBanner heading="${componentName}" />
        <div className="bg-grey">
            <div className="body-container"  >
                <div className="container-large pb-0 pt-5" >
                    <Row>
                        <Col xxxl="2" xs="12">
                            <div className="h5 result-title" >
                                {!searchLoading &&
                                    <div>
                                        {originData.length} {originData.length === 1 ? "record" : "records"}
                                    </div>
                                }
                            </div>
                            <div className={showXSFilters ? "filter-screen p-5 pt-0 scroll-container pb-2" : "filter-panel-column pe-4 mt-7 "}>

                            </div>
                        </Col>
                        <Col xxxl="10" className="text-end">
                            <div className="cd-header-large" >
                                <Row>
                                    <Col xs="4">
                                        <Button id="filter-button" className="rounded-pill mt-2 button-style" color="standard-primary" size="sm" >
                                            Filter
                                        </Button>
                                    </Col>
                                    <Col xs="8" className="text-end">
                                        <Button
                                            onClick={create${componentName} }
                                            color="standard-primary" className="text-center rounded-pill button-style me-3" size="sm" caret="true">
                                        <div>Create</div>
                                    </Button>
                                    <Button
                                        // onClick={() => downloadCSV(data)}
                                        color="standard-tertiary" className="text-center rounded-pill button-style" size="sm" caret="true">
                                        <div>Export data</div>
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                        <div className="ms-0  "   >
                            {searchLoading ?
                                <LoadingSpinner />
                                :
                                <div className="">
                                    <DataTable
                                        theme="solarized"
                                        paginationPerPage="10" customStyles={TableStyles}
                                        selectableRows
                                        selectableRowsSingle="true"
                                        responsive
                                        pagination
                                        dense
                                        noRowsPerPage={xRowsPerPage}
                                        progressPending={loading}
                                        columns={columns}
                                        data={data}
                                    />
                                </div>
                            }
                        </div>
                    </Col>
                </Row>


            </div>
        </div>
    </div >
        { showDetailModal && <${componentName} Modal showModalForm={true} page={page}
            closeModal={closeModal}
            ${keyField}={${keyField}} refreshData={refreshData}
        />
        }

    </>
)

}

export default ${componentName} `;


            const reactColumns = `

const ${componentName}Columns = [
    ${fieldDefinitions.map((field) => {
                const parts = field.formatLanding.split(";");
                const visible = parts[0] || "V";
                const width = parts[1] || "150";
                const format = parts[2] || "STRING";

                let selector;

                switch (format) {
                    case "DMYHMS":
                        selector = `row => {
          return (
            <div className="text-left">
              {row.${field.lowerCaseFieldName} !== null &&
                row.${field.lowerCaseFieldName}
                  ?.substring(8, 10) +
                "/" +
                row.${field.lowerCaseFieldName}
                  ?.substring(5, 7) +
                "/" +
                row.${field.lowerCaseFieldName}
                  ?.substring(0, 4) +
                " " +
                row.${field.lowerCaseFieldName}?.substring(11, 19)}
            </div>
          );
        }`;
                        break;
                    case "YMDHMS":
                        selector = `row => {
          return (
            <div className="text-left">
              {row.${field.lowerCaseFieldName} !== null &&
                row.${field.lowerCaseFieldName}
                  ?.substring(0, 4) +
                "-" +
                row.${field.lowerCaseFieldName}
                  ?.substring(5, 7) +
                "-" +
                row.${field.lowerCaseFieldName}
                  ?.substring(8, 10) +
                " " +
                row.${field.lowerCaseFieldName}?.substring(11, 19)}
            </div>
          );
        }`;
                        break;
                    case "YMD":
                        selector = `row => {
          return (
            <div className="text-left">
              {row.${field.lowerCaseFieldName} !== null &&
                row.${field.lowerCaseFieldName}
                  ?.substring(0, 4) +
                "-" +
                row.${field.lowerCaseFieldName}
                  ?.substring(5, 7) +
                "-" +
                row.${field.lowerCaseFieldName}?.substring(8, 10)}
            </div>
          );
        }`;
                        break;
                    case "YM":
                        selector = `row => {
          return (
            <div className="text-left">
              {row.${field.lowerCaseFieldName} !== null &&
                row.${field.lowerCaseFieldName}
                  ?.substring(0, 4) +
                "/" +
                row.${field.lowerCaseFieldName}
                  ?.substring(5, 7)}
            </div>
          );
        }`;
                        break;
                    default:
                        selector = `row => row.${field.lowerCaseFieldName}`;
                        // console.log(field.lowerCaseFieldName)
                        break;
                }

                return `
    {
        name: '${field.fieldDescription}',
        selector: ${selector},
        sortable: true,
        wrap: false,
        allowOverflow: false,
        minWidth: '${width}px',
        maxWidth: '${width}px',
        hide: ${visible === "H" ? true : false}
    }`;
            })
                }
];



export default ${componentName} Columns;

`;

            const reactEdit = `
import '../assets/css/common.css';
import React, { useState, useEffect, useRef } from 'react'
import base from '../services/baseService';
import { Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';

const ${componentName}Modal = ({ showModalForm, closeModal, page, ${keyField}, refreshData }) => {
    const [showModal, setShowModal] = useState(showModalForm);
    var dataURL = useRef("");
    ${fieldDefinitions
                    .filter(field => !formFieldsToIgnore.includes(field.fieldName.toUpperCase()))
                    .map(field => `const [${field.lowerCaseFieldName}, set${field.fieldName}] = useState("")`).join('\n    ')
                }



    const toggleModal = () => {
        setShowModal(showModalForm => !showModalForm);
        closeModal()
    }

    const handleChange = (event) => {
        const fieldValue = event.target.value;
        switch (event.target.name) {
            ${fieldDefinitions
                    .filter(field => !formFieldsToIgnore.includes(field.fieldName.toUpperCase()))
                    .map(field => `
                case "${field.lowerCaseFieldName}":
                    set${field.fieldName}(fieldValue);
                    break;
            `).join('')
                }
            default:
break;
        }
    }

useEffect(() => {
    dataURL.current = "${apiName}/qry"

    if (dataURL?.current) {

        dataURL.current = dataURL.current + "/" + ${keyField}
        base.getAll(dataURL.current).then((res) => {
            if (page === "edit") {

                ${fieldDefinitions
                    .filter(field => !formFieldsToIgnore.includes(field.fieldName.toUpperCase()))
                    .map(field =>
                        `set${field.fieldName}(res[0]?.${field.lowerCaseFieldName});`
                    ).join('\n    ')
                }
            }
        })
    };

}, [ID, page]);

const updateRecord = () => {

    var updatedRecord = {
        ${fieldDefinitions
                    .filter(field => !fieldsToIgnore.includes(field.fieldName.toUpperCase()))
                    .map(field =>
                        `"${field.lowerCaseFieldName}" : ${field.lowerCaseFieldName},`
                    ).join('\n    ')}

        }
base.update("${apiName}/upd/" + ${keyField}, updatedRecord).then((res) => {
    setShowModal(showModalForm => !showModalForm);
    closeModal()
    refreshData();
});
        
    }

const createRecord = () => {

    var newRecord = {
        ${fieldDefinitions
                    .filter(field => !fieldsToIgnore.includes(field.fieldName.toUpperCase()))
                    .map(field =>
                        `"${field.lowerCaseFieldName}" : ${field.lowerCaseFieldName},`
                    ).join('\n    ')}

        }

base.add("${apiName}/add", newRecord).then((res) => {
    setShowModal(showModalForm => !showModalForm);
    closeModal()
    refreshData()
});

       
    }

return (
    <>
        <Modal isOpen={showModal} size="lg" toggle={toggleModal} backdrop="static">
            <ModalHeader className="bg-modal" toggle={toggleModal}>
                <Row>
                    <Col>
                        <div className="md-header">${componentName}</div>
                    </Col>
                </Row>
            </ModalHeader>
            <ModalBody className="pb-0 mb-0">
                <div className="modal-large pb-2 mb-2" >

                    <div className="card rounded pt-2">
                        <Row>
                            ${fieldDefinitions
                    .map((field) => {
                        const parts = field.formatModal.split(";");
                        // console.log(parts, field.fieldDescription);
                        const visible = parts[0] || "V";
                        const width = parts[1] || "4";
                        // const format = parts[2] || "STRING";
                        const type = parts[3] || "text";

                        if (visible === "V") {
                            const fieldElement = `
        <Col xs="2" className="mt-3">
          <Label className="label-header">${field.fieldDescription}</Label>
        </Col>
        <Col xs="${width}" className="mt-3">
          <Input type="${type}" bsSize="sm" name="${field.lowerCaseFieldName}" id="${field.lowerCaseFieldName}" placeholder="" disabled={false} onChange={handleChange} value={${field.lowerCaseFieldName}} />
        </Col>
      `;
                            return fieldElement;
                        } else {
                            return "";
                        }
                    })
                    .join('\n')
                }

                        </Row >
                    </div >
                </div >
            </ModalBody >
            <ModalFooter className="">
                <>
                    <Button color="standard-tertiary" size="sm" className="b-width rounded-pill" onClick={toggleModal}>Cancel</Button>
                    <Button color="standard-primary" size="sm" className="b-width rounded-pill"
                        onClick={(page === "create" ? createRecord : updateRecord)}
                    >Save</Button>
                </>
            </ModalFooter>
        </Modal >

    </>


);
}


export default ${componentName} Modal;

`;
            // Set the generated C# methods in state
            setGeneratedCSharpMethods(cSharpMethodCode);

            // Set the generated code in state
            setGeneratedCSharpModel(cSharpModelCode);
            setGeneratedReactLanding(reactLanding);
            setGeneratedReactColumns(reactColumns);
            setGeneratedReactEdit(reactEdit);
            setGeneratedJSON(jsonCode);
        } catch (error) {
            console.error(error);
        }
    };

    const sqlToCSharpType = (sqlType) => {
        // You can extend this mapping as needed
        if (sqlType === 'varchar') return 'string';
        if (sqlType === 'nvarchar') return 'string';
        if (sqlType === 'int') return 'int';
        if (sqlType === 'datetime') return 'DateTime';
        if (sqlType === 'decimal') return 'decimal';
        return 'UnknownType';
    };

    const downloadFile = (content, filename) => {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    return (
        <>
            <CardHeader className="pt-3 pb-3" >
                <Row>
                    <Col xs="12" className="page-header">
                        <AiOutlineCode size={30} className="mb-1" color="#8bc5c5" />&nbsp;&nbsp;
                        <span className="section-heading">Code Generator</span>
                    </Col>
                </Row>
            </CardHeader>
            <div className="container-fluid" style={{ fontFamily: "monospace" }}>
                <Row>
                    <Col xs="12">
                        <CardBody>
                            <Row>
                                <Col xs="12">
                                    <Row>
                                        <Col xs="12" xxl="2" className="mt-2" >
                                            <Card className="card-sql" onClick={() => copyToClipboard(tableSQL)} style={{ cursor: "pointer" }}>
                                                <CardBody>
                                                    <Row>
                                                        <Col xs="8">
                                                            <span>Table SQL</span>&nbsp;
                                                        </Col>
                                                        <Col className="text-end">
                                                            <AiOutlineCopy size={25} />
                                                        </Col>
                                                    </Row>

                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col xs="12" xxl="2" className="mt-2" >
                                            <Card className="card-sql" onClick={() => copyToClipboard(viewSQL)} style={{ cursor: "pointer" }}>
                                                <CardBody>
                                                    <Row>
                                                        <Col xs="8">
                                                            <span>View SQL</span>&nbsp;
                                                        </Col>
                                                        <Col className="text-end">
                                                            <AiOutlineCopy size={25} />
                                                        </Col>
                                                    </Row>

                                                </CardBody>
                                            </Card>
                                        </Col>


                                    </Row>
                                </Col>
                                <Col xs="12" className="mt-3" >
                                    <Row>
                                        <Col xs="12" style={{ fontFamily: "monospace", fontSize: "10px" }}>
                                            {/* <div>
                                                Table Name&nbsp;&nbsp;|&nbsp;&nbsp;Field Name&nbsp;&nbsp;|&nbsp;&nbsp;Field Type&nbsp;&nbsp;|&nbsp;&nbsp;Field Length&nbsp;&nbsp;|&nbsp;&nbsp;Field Description&nbsp;&nbsp;|&nbsp;&nbsp;Format Landing&nbsp;&nbsp;|&nbsp;&nbsp;Format Modal&nbsp;&nbsp;|&nbsp;&nbsp;Key Field
                                            </div> */}
                                            <Input
                                                type="textarea"
                                                value={sqlScript}
                                                onChange={(event) => handleChange(event)}

                                                // value={sqlScript}
                                                // onChange={(event) => {
                                                //     const updatedValues = [...inputValues];
                                                //     updatedValues[index] = event.target.value;
                                                //     setInputValues(updatedValues);
                                                // }}
                                                // onPaste={(event) => handlePaste(event)}
                                                placeholder="Enter SQL ouptut here"
                                                maxxlength={5000}
                                                style={{ fontFamily: "monospace", height: "350px", fontSize: "13px", tabSize: "20" }}

                                            ></Input>
                                            {/* {inputValues.map((value, index) => (
                                                <Input
                                                    key={index}
                                                    type="text"
                                                    value={value}
                                                    onChange={(event) => {
                                                        const updatedValues = [...inputValues];
                                                        updatedValues[index] = event.target.value;
                                                        setInputValues(updatedValues);
                                                    }}
                                                    onPaste={(event) => handlePaste(event, index)}
                                                />
                                            ))} */}
                                        </Col>
                                        <Col>

                                            <Input
                                                className="mt-3"
                                                type="text"
                                                value={tableToUpdate}
                                                style={{ fontFamily: "monospace", fontSize: "13px" }}
                                                onChange={(e) => setTableToUpdate(e.target.value)}
                                                placeholder="Table to update"
                                                maxxlength={100}
                                            ></Input>
                                        </Col>
                                    </Row>
                                </Col>


                            </Row>

                            <Button className="mt-2" onClick={generateCode}>Generate Code</Button>

                            <Row>


                                <Col xs="12" xxl="4">
                                    {generatedCSharpModel !== "" &&
                                        <div className="mt-4" style={{ fontWeight: "bold", fontSize: "18px" }}>
                                            .NET
                                        </div>
                                    }
                                    <Row>
                                        {generatedCSharpModel !== "" &&

                                            <Col xs="12" xxl="6">

                                                <div className="mt-2">
                                                    <Card className="card-net" style={{ cursor: "pointer" }} onClick={() => downloadFile(generatedCSharpModel, `${tableFile}_CSharpModel.txt`)}>
                                                        <CardBody >
                                                            <Row>
                                                                <Col xs="8">
                                                                    C# model
                                                                </Col>
                                                                <Col className="text-end">
                                                                    <AiOutlineDownload size={25} />
                                                                </Col>
                                                            </Row>
                                                        </CardBody>

                                                    </Card>


                                                </div>
                                            </Col>
                                        }
                                        {generatedCSharpMethods !== "" &&
                                            <Col xs="12" xxl="6">
                                                <div className=" mt-2">
                                                    <Card className="card-net" style={{ cursor: "pointer" }} onClick={() => downloadFile(generatedCSharpMethods, `${tableFile}_CSharpMethods.txt`)}>
                                                        <CardBody >
                                                            <Row>
                                                                <Col xs="8">
                                                                    C# methods
                                                                </Col>
                                                                <Col className="text-end">
                                                                    <AiOutlineDownload size={25} />
                                                                </Col>
                                                            </Row>
                                                        </CardBody>

                                                    </Card>


                                                </div>
                                            </Col>
                                        }
                                    </Row>
                                </Col>

                                <Col xs="12" xxl="6">
                                    {generatedReactColumns !== "" &&
                                        <div className="mt-4" style={{ fontWeight: "bold", fontSize: "18px" }}>
                                            ReactJS
                                        </div>
                                    }

                                    <Row>


                                        {generatedReactLanding !== "" &&

                                            <Col xs="12" xxl="4">

                                                <div className="mt-2">

                                                    <Card className="card-react" style={{ cursor: "pointer" }} onClick={() => downloadFile(generatedReactLanding, `${tableFile}.js`)}>
                                                        <CardBody >
                                                            <Row>
                                                                <Col xs="8">
                                                                    Landing
                                                                </Col>
                                                                <Col className="text-end">
                                                                    <AiOutlineDownload size={25} />
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </Col>
                                        }
                                        {generatedReactColumns !== "" &&
                                            <Col xs="12" xxl="4">
                                                <div className="mt-2">

                                                    <Card className="card-react" style={{ cursor: "pointer" }} onClick={() => downloadFile(generatedReactColumns, `${tableFile}Columns.js`)}>
                                                        <CardBody >
                                                            <Row>
                                                                <Col xs="8">
                                                                    Grid
                                                                </Col>
                                                                <Col className="text-end">
                                                                    <AiOutlineDownload size={25} />
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </Col>
                                        }
                                        {generatedReactEdit !== "" &&
                                            <Col xs="12" xxl="4">
                                                <div className="mt-2">

                                                    <Card className="card-react" style={{ cursor: "pointer" }} onClick={() => downloadFile(generatedReactEdit, `${tableFile}Modal.js`)}>
                                                        <CardBody >
                                                            <Row>
                                                                <Col xs="8">
                                                                    Modal
                                                                </Col>
                                                                <Col className="text-end">
                                                                    <AiOutlineDownload size={25} />
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </Col>
                                        }

                                    </Row>
                                </Col>
                                <Col xs="12" xxl="2">
                                    {generatedJSON !== "" &&
                                        <div className="mt-4" style={{ fontWeight: "bold", fontSize: "18px" }}>
                                            JSON SQL
                                        </div>
                                    }
                                    <Row>
                                        {generatedJSON !== "" &&

                                            <Col xs="12">

                                                <div className="mt-2">
                                                    <Card className="card-json" style={{ cursor: "pointer" }} onClick={() => downloadFile(generatedJSON, `${tableFile}_JSON.txt`)}>
                                                        <CardBody >
                                                            <Row>
                                                                <Col xs="8">
                                                                    POST / PUT
                                                                </Col>
                                                                <Col className="text-end">
                                                                    <AiOutlineDownload size={25} />
                                                                </Col>
                                                            </Row>
                                                        </CardBody>

                                                    </Card>


                                                </div>
                                            </Col>
                                        }

                                    </Row>
                                </Col>
                            </Row>

                            {generatedCSharpModel !== "" &&
                                <Button className="mt-4" onClick={() => {
                                    downloadFile(generatedCSharpMethods, `${tableFile} _CSharpMethods.txt`)
                                    downloadFile(generatedCSharpMethods, `${tableFile} _CSharpMethods.txt`)
                                    downloadFile(generatedReactLanding, `${tableFile}.js`)
                                    downloadFile(generatedReactColumns, `${tableFile} Columns.js`)
                                    downloadFile(generatedReactEdit, `${tableFile} Modal.js`)
                                }}
                                >
                                    Download all
                                </Button>
                            }
                        </CardBody>

                    </Col >
                </Row >
            </div >


        </>
    );
}


export default CodeGenerator;
