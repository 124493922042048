import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap'
import TextOnImage from '../../shared/textOnImage'
import image from '../../../assets/images/careers.jpg'
const CareersPage = () => {

    useEffect(() => {
        document.title = 'Careers | Join our team and advance your IT career | Mobyte';

        // Create meta description
        const metaDescription = document.createElement('meta');
        metaDescription.setAttribute('name', 'description');
        metaDescription.content = 'Explore careers opportunities at Mobyte';

        // Create meta keywords
        const metaKeywords = document.createElement('meta');
        metaKeywords.setAttribute('name', 'keywords');
        metaKeywords.content = 'Software engineer, IT consultant,  React developer, .NET developer, full-stack developer';

        const metaRobots = document.createElement('meta');
        metaRobots.setAttribute('name', 'robots');
        metaRobots.content = 'index';
        document.head.appendChild(metaRobots);

        // Append both meta elements to the head
        document.head.appendChild(metaDescription);
        document.head.appendChild(metaKeywords);
        document.head.appendChild(metaKeywords);

        return () => {
            // Clean up added meta tags when component unmounts
            document.head.removeChild(metaDescription);
            document.head.removeChild(metaKeywords);
            document.head.removeChild(metaRobots);

        };
    }, []);

    const subheading = (
        <span className="hero-text-1 white-text">
            Careers
        </span>
    )

    return (
        <>
            <div className="">


                <TextOnImage imageUrl={image} heading={"About us"} subheading={subheading} fromHome={false} altText={"Team in meeting"} />

                <div className="container-large">
                    <div className='heading-block'>
                        <h2 className={`hero-text-2 secondary-text`}>
                            Join our team and advance your&nbsp;<span className="header-brush">IT career</span>&nbsp;
                        </h2>
                    </div>
                    <Row className="mb-4">
                        <Col xs="12" className="mb-4">
                            <div >
                                <p style={{ textAlign: "left" }} className="standard-text">
                                    At Mobyte, we are committed to creating a diverse and inclusive workplace where all team members can thrive. We believe that our success is built on the unique perspectives, experiences, and skills that each individual brings to the table. We are a dynamic and growing organization, and we are always looking for talented individuals to help us achieve our goals. Whether you are just starting out in your career or are an experienced professional, we offer a range of exciting opportunities across a variety of functions. We pride ourselves on providing a supportive and collaborative work environment, where team members are encouraged to learn, grow, and innovate. We also offer competitive compensation packages, comprehensive benefits, and a range of professional development opportunities to help you advance your career.
                                </p>
                                <p style={{ textAlign: "left" }} className="standard-text">
                                    If you are passionate about making a difference and are excited about the prospect of joining a dynamic and mission-driven team, we encourage you to get in contact:
                                </p>
                                <p>
                                    <a href="mailto:info@mobyte.com">info@mobyte.com</a>
                                </p>
                                <p className="standard-text">
                                    We look forward to hearing from you!
                                </p>
                            </div>
                            <div>
                                <span style={{ color: "#008080 !important" }} className="hero-text-2">Mobyte</span>
                            </div>
                        </Col>

                    </Row>

                </div>

            </div>

        </>
    );
};

export default CareersPage;