import { Spinner } from "reactstrap"


const Loader = () => {
    return (
        <>
            <div className="col-12">
                <div >
                    {/* className="loadspinner" */}
                    <Spinner style={{ width: '2rem', height: '2rem' }} />
                </div>
            </div>
        </>
    )
}

export default Loader


