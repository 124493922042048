import axios from 'axios';


var axiosinstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
});


const sendRequest = async (method, url, data, tokenRequest, additionalConfig = {}) => {
    try {
        const config = {
            method,
            url,
            data,
            withCredentials: false,
            ...additionalConfig,
        };
        const response = await axiosinstance(config);
        return response || response;
    } catch (error) {
        console.error(`Error making ${method.toUpperCase()} request to ${url}:`, error.message);
        throw error;
    }
};

const base = {
    getAll: (url) => sendRequest("get", url, null),

    get: (url, id) => sendRequest("get", `${url}/${id}`, null),

    qry: (url, id) => sendRequest("get", `${url}/${id}`, null),

    add: (url, data) => sendRequest("post", url, data),
};

export default base;