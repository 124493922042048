import React from 'react'
import { useState, useRef } from 'react';
import { Col, Row, Button, Label, Input, Modal, ModalBody, ModalHeader, FormGroup, ModalFooter } from 'reactstrap';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import base from '../../../services/BaseService'
import { Editor } from '@tinymce/tinymce-react';


const BlogDetailModal = ({ id, createDetail, closeModal, showModalForm, singleDetailData, saveDetail, setSaveDetail, detailData, setMoveUpDisabled, setMoveDownDisabled }) => {

    const [showModal, setShowModal] = useState(showModalForm)
    const [heading, setHeading] = useState(singleDetailData[0]?.heading)
    const detailID = singleDetailData[0]?.id
    const tinyRef = useRef(null)

    const toggleModal = () => {
        closeModal()
        setShowModal(showModal => !showModal);
    }

    const deleteItem = () => {
        const detailToDelete = {
            "blogID": id,
            "heading": heading,
            "display": true,
            "up_date": "2024-07-31T10:45:00",
            "up_User": "lawrence.whale@mobyte.co.uk",
            "rel_rec": 1,
            "sysid": 1,
            "id": detailID
        }
        base.update("managedata/blogdetail/del/" + detailID, detailToDelete).then((res) => {

            toggleModal()
            setSaveDetail(new Date().getTime());
            setMoveDownDisabled(true)
            setMoveUpDisabled(true)
        })
    }

    const updateItem = () => {
        const editor = tinyRef.current
        const plainText = editor.getContent({ format: 'text' });
        const htmlText = editor.getContent({ format: 'html' });

        if (createDetail === false) {

            const updatedDetails =
            {
                "blogID": id,
                "heading": heading,
                "bodyText": htmlText,
                "plainText": plainText,
                "sequence": singleDetailData[0].sequence,
                "display": true,
                "up_date": "2024-07-31T10:45:00",
                "up_User": "lawrence.whale@mobyte.co.uk",
                "rel_rec": 1,
                "sysid": 1,
                "id": 1
            }

            base.update("managedata/blogdetail/upd/" + detailID, updatedDetails).then((res) => {
                toggleModal()
                setSaveDetail(!saveDetail)
            })
        } else {
            const newDetail =
                [{
                    "blogID": id,
                    "heading": heading,
                    "bodyText": htmlText,
                    "plainText": plainText,
                    "sequence": detailData.length,
                    "display": true,
                    "up_date": "2024-07-31T10:45:00",
                    "up_User": "lawrence.whale@mobyte.co.uk",
                    "rel_rec": 1,
                    "sysid": 1,
                }]

            base.add("managedata/blogdetail/add", newDetail).then((res) => {
                toggleModal()
                setSaveDetail(!saveDetail)
            })
        }
        setMoveDownDisabled(true)
        setMoveUpDisabled(true)
    }

    return (
        <>
            <Modal isOpen={showModal} size="xl" toggle={toggleModal} backdrop="static">
                <ModalHeader className="bg-modal" toggle={toggleModal}><div className="page-header">Blog details</div></ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Row className="mb-2 align-items-center">
                            <Col md="3" lg="2">
                                <Label className="label-text" >Heading</Label>
                            </Col>
                            <Col md="9" lg="10">
                                <Input type="text" className="input-text-locked" name="heading" id="heading" placeholder="" onChange={(e) => setHeading(e.target.value)} value={heading} />
                            </Col>
                        </Row>
                        <Row className="mb-2 align-items-center">
                            <Col md="3" lg="2">
                                <Label className="label-text" >Text</Label>
                            </Col>
                            <Col md="9" lg="10">
                                <Editor
                                    ref={tinyRef}
                                    apiKey='7ksybh1b458nhxrtob193kpx60z1x20kulvp9owf7h7z5tr0'
                                    init={{
                                        plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker',
                                        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                    }}
                                    initialValue={singleDetailData[0]?.bodyText}
                                    onInit={(evt, editor) => tinyRef.current = editor}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    {createDetail === false ? <Button color="success" size="sm" className="btn-text b-width" onClick={() => deleteItem()} >Delete</Button> : ""}
                    <Button color="success" size="sm" className="btn-text b-width" onClick={() => updateItem()} >Save</Button>
                    <Button color="secondary" size="sm" className="btn-text b-width" onClick={toggleModal}>Close</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default BlogDetailModal
