import React from "react";
import { AiOutlineCode, AiOutlineBulb, AiOutlineOrderedList, AiOutlineCheckCircle } from 'react-icons/ai'
import { IoChatbubbleEllipsesOutline, IoDocumentTextOutline, IoPencilSharp, IoRocketOutline } from 'react-icons/io5'
import { Row, Col } from 'reactstrap'

export default function ProcessItems() {

    const processItems = [
        {
            id: 1,
            icon: <AiOutlineBulb className='icon-large' color="#003333" />,
            heading: "Ideation",
            subheading: "The first stage involves brainstorming and generating ideas for potential projects. This could come from internal stakeholders or external customers.",
        },
        {
            id: 2,
            icon: <AiOutlineOrderedList className='icon-large' color="#003333" />,
            heading: "Requirements",
            subheading: "We work with stakeholders to gather requirements for the project. This includes defining the scope, objectives, and constraints for the project."
        },
        {
            id: 3,
            icon: <IoDocumentTextOutline className='icon-large' color="#fff" />,
            heading: "Planning",
            subheading: "We create a detailed plan for the project, including a timeline, budget, resources required, and the process for executing the project.",
        },
        {
            id: 4,
            icon: <IoPencilSharp className='icon-large' color="#003333" />,
            heading: "Design",
            subheading: "We create the design for the solution, including both the technical design and the user interface design.",
        },
        {
            id: 5,
            icon: <AiOutlineCode className='icon-large' color="#003333" />,
            heading: "Development",
            subheading: "We start developing the solution, including writing code, building the infrastructure, and testing the solution.",
        },
        {
            id: 6,
            icon: <AiOutlineCheckCircle className='icon-large' color="#fff" />,
            heading: "Testing",
            subheading: "We thoroughly test the solution to ensure that it meets the requirements and works as intended.",
        },
        {
            id: 7,
            icon: <IoRocketOutline className='icon-large' color="#003333" />,
            heading: "Deployment",
            subheading: "We deploy the solution to the customer's environment, making it available for use.",
        },
        {
            id: 8,
            icon: <IoChatbubbleEllipsesOutline className='icon-large' color="#003333" />,
            heading: "Support and Maintenance",
            subheading: "We provide ongoing maintenance and support for the solution, including fixing any bugs or issues that arise and adding new features as needed.",
        },
    ]



    const getBackgroundColor = (index) => {
        const colors = ['#fff', '#ffc266', '#003333'];
        return colors[index % colors.length];
    };

    return (
        <>
            <Row className="container-large" style={{ backgroundColor: '#fff' }
            }>
                <div className='heading-block'>
                    <h2 className={`hero-text-2 secondary-text`}>
                        Creating
                        <span className={"accent-1-text emph-brush"}> innovative IT solutions </span>
                        through our proven 8-step process
                    </h2>
                </div>

            </Row>
            {processItems.map((item, index) => {
                const color = getBackgroundColor(index);
                let headColor
                let textColor
                let emphColor
                switch (color) {
                    case '#ffa31a': //orange
                        headColor = 'secondary-text'
                        textColor = 'accent-4'
                        emphColor = 'secondary-text'
                        break;
                    case '#003333': // dark teal
                        headColor = 'white-text'
                        textColor = 'neutral-2-text'
                        emphColor = 'white-text'
                        break;
                    default: //white or other
                        headColor = 'secondary-text'
                        textColor = 'accent-4'
                        emphColor = 'secondary-text'
                        break;
                }
                return (
                    <Row className="container-large" key={index} style={{ backgroundColor: color }
                    }>

                        <Row className="content-block">
                            {
                                index % 2 === 0 ? (
                                    <>
                                        <Col xs="4" className={`text-center block-left-scroll ${index === 0 ? 'visible' : ''}`}>
                                            {item.icon}
                                        </Col>
                                        <Col className={`block-right-scroll ${index === 0 ? 'visible' : ''}`}>
                                            <h2 className={`hero-text-2 ${headColor}`}>
                                                Stage {index + 1}: <span className={emphColor}>{item.heading}</span>
                                            </h2>
                                            <p style={{ textAlign: "left" }} className={`content-body ${textColor}`}>{item.subheading}</p>
                                        </Col>

                                    </>
                                ) : (
                                    <>
                                        <Col xs="4" className={`text-center block-left-scroll `}>
                                            {item.icon}
                                        </Col>
                                        <Col className={`block-right-scroll `}>
                                            <h2 className={`hero-text-2 ${headColor}`}>
                                                Stage {index + 1}: <span className={emphColor}>{item.heading}</span>
                                            </h2>
                                            <p style={{ textAlign: "left" }} className={`content-body ${textColor}`}>{item.subheading}</p>
                                        </Col>
                                    </>
                                )
                            }
                        </Row>
                    </Row>
                )

            })}


        </>
    );
}