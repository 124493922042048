import React, { useEffect, useState } from 'react';
import TextOnImage from '../../shared/textOnImage'
import image from '../../../assets/images/03-thumbnail.jpg'
import { Link } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { IoArrowBack, IoConstructOutline } from "react-icons/io5";
import base from '../../../services/naBaseService';
import { useParams } from 'react-router-dom';

export default function ProjectDetail() {

    const [projectDetail, setProjectDetail] = useState([]);
    const [project, setProject] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { projectId } = useParams();

    useEffect(() => {

        const promises = [
            base.getAll(`project/qry/${projectId}`),
            base.getAll(`project/detail/qry/${projectId}`),
        ];

        Promise.all(promises)
            .then(([res, detail]) => {
                setProject(res);
                setProjectDetail(detail);
                setDataLoaded(true)
            }).finally(() => {
                setIsLoading(false); // Stop the loading state
            });
    }, [projectId]);

    return (
        <>
            <TextOnImage imageUrl={image}
                // heading={"Coming soon"}
                subheading={<span >
                    <span className="hero-text-1 white-text">{project.heading}</span>&nbsp;&nbsp;
                </span>}
                fromHome={false}
                altText={"Laptop displaying code editor on desk"} />

            <div className="container-large">
                <Link to="/projects" >
                    <Button className="mt-3 button-white">
                        <div className="d-flex">
                            <div >
                                <IoArrowBack size={25} />
                            </div>
                            &nbsp;&nbsp;
                            <div style={{ paddingTop: "1px" }}>Projects</div>
                        </div>
                    </Button>
                </Link>
                {isLoading ? (
                    <div className="m-5 p-5" style={{ minHeight: "500px" }}>
                    </div>
                ) : dataLoaded ? (
                    projectDetail.length > 0 ? (
                        <>
                            <div className="heading-block">
                                <h2 className="hero-text-2">{project.heading}</h2>
                            </div>
                            <div className="mb-5">
                                <p style={{ textAlign: "left" }} className="content-body">
                                    {project.subheading}
                                </p>
                                {projectDetail.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <h3 className="blog-heading mt-5">{item.heading}</h3>
                                        <p
                                            style={{ textAlign: "left" }}
                                            className="content-body"
                                            dangerouslySetInnerHTML={{ __html: item.bodyText }}
                                        ></p>
                                    </React.Fragment>
                                ))}
                            </div>
                        </>
                    ) : (
                        <Row className="container-large mt-5">
                            <Col xs="4" className="text-center block-left-visible">
                                <IoConstructOutline className="icon-large mb-3" />
                            </Col>
                            <Col className="block-right-visible">
                                <h2 className={`hero-text-2 secondary-text`}>
                                    <span>{"This project page is still under construction"}</span>
                                </h2>
                                <p className={`content-body secondary-text`}>
                                    {"Please check back soon to find out more about our success stories."}
                                </p>
                            </Col>
                        </Row>
                    )
                ) : null}



            </div>




        </>


    );
}
