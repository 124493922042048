import { Col, Row, Card } from 'reactstrap';
import React, { useState, useEffect } from "react";
import imgT1 from '../../../assets/images/01-thumbnail.jpg'
import imgT2 from '../../../assets/images/aviation.jpg'
import imgT3 from '../../../assets/images/office.jpg'
import imgT4 from '../../../assets/images/04-thumbnail.jpg'
import imgT5 from '../../../assets/images/cards1.jpg'
import imgT6 from '../../../assets/images/oil-rig.jpg'
import { Link } from "react-router-dom";
import base from '../../../services/naBaseService';

export default function Projects({ style }) {

    const [projectsItems, setProjectsItems] = useState([])

    useEffect(() => {
        base.getAll("project/qry").then((res) => {
            setProjectsItems(res.data);
        });
    }, []);

    return (
        <Row>
            {projectsItems.map((item, index) => {

                const projectID = item.id
                let image;
                switch (projectID) {
                    case 1:
                        image = imgT2
                        break;
                    case 3:
                        image = imgT3
                        break;
                    case 4:
                        image = imgT1

                        break;
                    case 5:
                        image = imgT5
                        break;
                    case 7:
                        image = imgT6
                        break;
                    case 8:
                        image = imgT4
                        break;
                    default:
                        break;
                }

                return (
                    <React.Fragment key={`blog_${index}`}>
                        {item.display === true ?
                            <Col xl="4" lg="6" xs="12" className="">
                                <div>
                                    <Card className="mb-4 mt-1 ms-2 me-2" style={style}>
                                        <div className="">
                                            <img className="img-fluid" src={image} alt={item.altText} />
                                        </div>
                                        <div className="portfolio-caption">
                                            <div className="portfolio-caption-heading">{item.heading}</div>
                                            <div className="portfolio-caption-subheading">{item.subheading}</div>
                                            <div className="portfolio-caption-subheading2 pt-2">{item.read_time}</div>
                                        </div>
                                        <Row>
                                            <Col xs="12" className="mb-2 text-center">
                                                <Link to={`/projects/${item.id}`}
                                                    style={{ textDecoration: "none" }}>
                                                    <button className="cta-button ms-2" size="md" >Learn more</button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </Card>
                                </div>
                            </Col>
                            :
                            ""
                        }
                    </React.Fragment>
                );
            })}
        </Row>
    );
}