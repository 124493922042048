import React, { useEffect } from 'react';
import TextOnImage from '../../shared/textOnImage.js'
import image from '../../../assets/images/cables.jpg'

const Terms = () => {

    useEffect(() => {
        document.title = 'Terms of use | Mobyte';
    }, []);

    const subheading = (
        <span className="hero-text-1 white-text">
            Terms of use
        </span>
    )

    return (
        <>
            <TextOnImage imageUrl={image} heading={"About us"} subheading={subheading} fromHome={false} altText={"Connected cables"} />


            <div className="mb-5 mt-4">
                <div className="container-large">
                    <div className="pb-4">
                        Last updated: 10th Febuary 2023
                    </div>
                    <ol>
                        <li className="pb-2">
                            <span className="terms-header">Introduction</span>
                            <p style={{ textAlign: "left" }} >
                                This website (www.mobyte.com) is owned and operated by Mobyte Ltd. (“Company”, “we” or “us”). By using this website, you agree to be bound by the following terms and conditions (“Terms of Use” or “Agreement”). If you do not agree with these Terms of Use, please do not use this website.

                            </p>
                        </li>
                        <li className="pb-2">
                            <span className="terms-header">Use of website</span>
                            <p style={{ textAlign: "left" }} >
                                The content of this website, including text, graphics, logos, images, and software, is the property of the Company or its licensors, and is protected by copyright and trademark laws. You may access, use, and display the content of this website for your personal and non-commercial use only. You may not modify, distribute, or publish any content from this website without the express permission of the Company.
                            </p>
                        </li>
                        <li className="pb-2">
                            <span className="terms-header">Disclaimer</span>
                            <p style={{ textAlign: "left" }}>
                                The information and materials on this website are provided on an “as is” basis without warranty of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement. The Company makes no representation or warranty as to the accuracy or completeness of the information and materials on this website. The Company may make changes to the content of this website at any time without notice.
                            </p>
                        </li>
                        <li className="pb-2">
                            <span className="terms-header">Limitation of liability</span>
                            <p style={{ textAlign: "left" }}>
                                The Company shall not be liable for any damages arising out of or in connection with the use of this website. This includes, but is not limited to, direct, indirect, incidental, punitive, and consequential damages.
                            </p>
                        </li>
                        <li className="pb-2">
                            <span className="terms-header">Linking</span>
                            <p style={{ textAlign: "left" }}>
                                This website may contain links to other websites. The Company does not endorse or make any representation about any other websites, and is not responsible for the accuracy or reliability of any information, data, opinions, advice, or statements contained on those websites.
                            </p>
                        </li>
                        <li className="pb-2">
                            <span className="terms-header">Indemnification</span>
                            <p style={{ textAlign: "left" }}>
                                You agree to indemnify, defend, and hold harmless the Company and its officers, directors, employees, agents, licensors, and suppliers from and against all losses, expenses, damages, and costs, including reasonable attorneys’ fees, resulting from any violation of these Terms of Use or any activity related to your use of this website.
                            </p>
                        </li>
                        <li className="pb-2">
                            <span className="terms-header">Termination</span>
                            <p style={{ textAlign: "left" }}>
                                The Company may terminate your access to this website at any time without notice. Upon termination, you must destroy all content obtained from this website.
                            </p>
                        </li>
                        <li className="pb-2">
                            <span className="terms-header">Governing law</span>
                            <p style={{ textAlign: "left" }}>
                                These Terms of Use shall be governed by and construed in accordance with UK laws, without giving effect to any principles of conflicts of law.
                            </p>
                        </li>
                        <li className="pb-2">
                            <span className="terms-header">Changes to terms of use</span>
                            <p style={{ textAlign: "left" }}>
                                The Company reserves the right to change these Terms of Use at any time without notice. Your continued use of this website following any changes to these Terms of Use will indicate your acceptance of the revised terms.
                            </p>
                        </li>
                        <li className="pb-2">
                            <span className="terms-header">Contact us</span>
                            <p style={{ textAlign: "left" }}>
                                If you have any questions regarding these Terms of Use, please contact us at <a href="mailto:info@mobyte.com">info@mobyte.com</a>
                            </p>
                        </li>
                    </ol>


                </div>
            </div>
        </>
    );
};

export default Terms;