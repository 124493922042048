import React from 'react';

const Privacy = () => {
    return (
        <>
            <div className="mb-5">

                <div className="bg-teal ps-5 pe-5 pt-3 pb-3 mb-3">
                    <div className="main-heading">Responsibility</div>
                    <p className="main-subheading">At Mobyte, we strive to ensure our website is accessible and functioning properly, we protect the privacy and security of users' information, and ensure that the content and services provided on the website are accurate and comply with all applicable laws and regulations.</p>
                </div>
                <div className="container">
                    <ol>
                        <li>
                            <span className="terms-header">Uptime and Performance</span>
                            <p>
                                The website owner is responsible for ensuring that the website is available and functioning properly at all times. This includes regularly checking and fixing any technical issues that may arise.
                            </p>
                        </li>
                        <li>
                            <span className="terms-header">Privacy and Security</span>
                            <p>
                                The website owner is responsible for protecting the privacy and security of users' personal information, such as names, addresses, and payment information. This may include implementing secure socket layer (SSL) encryption, regularly monitoring the website for security vulnerabilities, and complying with relevant privacy laws and regulations.
                            </p>
                        </li>
                        <li>
                            <span className="terms-header">Content Accuracy</span>
                            <p>
                                The website owner is responsible for ensuring that the content and services provided on the website are accurate and up-to-date. This may include regularly reviewing and updating the content, as well as removing any outdated or incorrect information.
                            </p>
                        </li>
                        <li>
                            <span className="terms-header">Compliance with Laws</span>
                            <p>
                                The website owner is responsible for ensuring that the website complies with all applicable laws and regulations, including but not limited to consumer protection, advertising, and data protection laws.
                            </p>
                        </li>
                        <li>
                            <span className="terms-header">User Support</span>
                            <p>
                                The website owner is responsible for providing user support and resolving any issues that users may encounter while using the website. This may include providing a customer support hotline or a contact form for users to reach out for help.
                            </p>
                        </li>
                    </ol>
                    <div>
                        Last updated: 10th Febuary 2023.
                    </div>
                </div>

            </div>

        </>
    );
};

export default Privacy;