import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import servicesData from './servicesData';
import TextOnImage from '../../shared/textOnImage'
import image from '../../../assets/images/03-thumbnail.jpg'
import { Row, Col, Button } from 'reactstrap'
import { Link } from 'react-router-dom';
import { IoArrowBack, IoCloseCircleSharp } from "react-icons/io5";

export default function ServiceDetail() {
    const { serviceId } = useParams();
    const service = servicesData.find(item => item.id === serviceId);

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    useEffect(() => {
        if (service === undefined) {
            document.title = `Services | Mobyte`;

        } else {
            document.title = `${service.heading} | Mobyte`;
            // Create meta description
            const metaDescription = document.createElement('meta');
            metaDescription.setAttribute('name', 'description');
            metaDescription.content = `${service.heading}`;

            // Create meta keywords
            const metaKeywords = document.createElement('meta');
            metaKeywords.setAttribute('name', 'keywords');
            metaKeywords.content = `${service.keywords}`

            const metaRobots = document.createElement('meta');
            metaRobots.setAttribute('name', 'robots');
            metaRobots.content = 'index';
            document.head.appendChild(metaRobots);

            // Append both meta elements to the head
            document.head.appendChild(metaDescription);
            document.head.appendChild(metaKeywords);
            document.head.appendChild(metaKeywords);

            return () => {
                // Clean up added meta tags when component unmounts
                document.head.removeChild(metaDescription);
                document.head.removeChild(metaKeywords);
                document.head.removeChild(metaRobots);

            };
        }
    }, [service]);


    const getBackgroundColor = (index) => {
        const colors = ['#fff', '#ffc266', '#003333'];
        return colors[index % colors.length];
    };

    return (
        !service ? (
            <>
                <TextOnImage key={"noProjectTOI"} imageUrl={image} heading={"Oops"} subheading={<span >
                    <span className="hero-text-1 white-text">Service not offered</span>&nbsp;&nbsp;
                    {/* that empower your business */}
                </span>} fromHome={false} altText={"Laptop displaying code editor on desk"} />
                <Row key={"noProjectReturn"} className="container-large">
                    <Col>
                        <Link to="/services" >
                            <Button className="mt-3 button-white">
                                <div className="d-flex">
                                    <div >
                                        <IoArrowBack size={25} />

                                    </div>
                                    &nbsp;&nbsp;
                                    <div style={{ paddingTop: "1px" }}>Services</div>
                                </div>
                            </Button>

                        </Link>
                    </Col>
                </Row>
                <Row key={"noProjectROW"} className="container-large mt-5">

                    <>
                        <Col xs="4" className={`text-center block-left-scroll ${isVisible ? 'visible' : ''}`}>
                            <IoCloseCircleSharp className='icon-large  mb-3' />
                        </Col>
                        <Col className={`block-right-scroll ${isVisible ? 'visible' : ''}`}>
                            <h2 className={`hero-text-2 secondary-text`}>
                                <span >{"We don't currently offer that service"}</span>
                            </h2>
                            <p className={`content-body secondary-text`}>{"You seem to have taken a wrong turn. Please return to our services page to see all of our great offerings!"}</p>
                        </Col>

                    </>
                </Row>

            </>
        ) : (
            <>
                <TextOnImage key={"projectTOI"} imageUrl={image} heading={service.heading} subheading={service.subHeading} fromHome={false} altText={"Laptop displaying code editor on desk"} />
                <Row key={"projectReturn"} className="container-large">
                    <Col>
                        <Link to="/services" >
                            <Button className="mt-3 button-white">
                                <div className="d-flex">
                                    <div >
                                        <IoArrowBack size={25} />

                                    </div>
                                    &nbsp;&nbsp;
                                    <div style={{ paddingTop: "1px" }}>Services</div>
                                </div>
                            </Button>

                        </Link>
                    </Col>
                </Row>



                {service.content.map((item, index) => {
                    const color = getBackgroundColor(index);
                    let headColor
                    let textColor
                    let emphColor
                    switch (color) {
                        case '#ffa31a': //orange
                            headColor = 'secondary-text'
                            textColor = 'accent-4'
                            emphColor = 'secondary-text'
                            break;
                        case '#003333': // dark teal
                            headColor = 'white-text'
                            textColor = 'neutral-2-text'
                            emphColor = 'white-text'
                            break;
                        default: //white or other
                            headColor = 'secondary-text'
                            textColor = 'accent-4'
                            emphColor = 'secondary-text'
                            break;
                    }
                    return (
                        <Row className="container-large" key={index} style={{ backgroundColor: color }
                        }>

                            <Row className="content-block">
                                {
                                    index % 2 === 0 ? (
                                        <>
                                            <Col xs="4" className={`text-center block-left-scroll ${index < 2 ? 'visible' : ''}`}>
                                                {item.icon}
                                            </Col>
                                            <Col className={`block-right-scroll ${index < 2 ? 'visible' : ''}`}>
                                                <h2 className={`hero-text-2 ${headColor}`}>
                                                    <span className={emphColor}>{item.heading}</span>
                                                </h2>
                                                <p style={{ textAlign: "left" }} className={`content-body ${textColor}`}>{item.subheading}</p>
                                            </Col>

                                        </>
                                    ) : (
                                        <>
                                            <Col xs="4" className={`text-center block-left-scroll ${index < 2 ? 'visible' : ''}`}>
                                                {item.icon}
                                            </Col>
                                            <Col className={`block-right-scroll ${index < 2 ? 'visible' : ''}`}>
                                                <h2 className={`hero-text-2 ${headColor}`}>
                                                    <span className={emphColor}>{item.heading}</span>
                                                </h2>
                                                <p style={{ textAlign: "left" }} className={`content-body ${textColor}`}>{item.subheading}</p>
                                            </Col>
                                        </>
                                    )
                                }
                            </Row>
                        </Row>
                    )

                })}


            </>)

    );
}
