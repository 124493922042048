import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { AiOutlineSolution, AiOutlineRise, AiOutlineBranches, AiOutlineCode, AiOutlineApi } from 'react-icons/ai';
import { IoStatsChart, } from 'react-icons/io5';


export const ContactSection = () => {

    const iconSize = 72; // set the size of the icons
    const infoCards = [
        {
            icon: <AiOutlineSolution size={iconSize} />,
            text: "Tailored solutions that fit your business perfectly.",
        },
        {
            icon: <AiOutlineCode size={iconSize} />,
            text: "Partner with us for cutting-edge IT solutions.",
        },
        {
            icon: <AiOutlineRise size={iconSize} />,
            text: "Scale your IT to match your ambitions.",
        },
        {
            icon: <IoStatsChart size={iconSize} />,
            text: "Strong focus on optimizing data capture and reporting to drive business efficiency.",
        },
        {
            icon: <AiOutlineApi size={iconSize} />,
            text: "Expertise in data manipulation and integration into custom systems.",
        },
        {
            icon: <AiOutlineBranches size={iconSize} />,
            text: "Flexible and adaptive development processes.",
        },
    ];

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);


    return (
        <div className={`container-large pt-4 pb-3 `} style={{ overflow: "hidden" }} >
            <Row>
                <Col xl="6" lg="12" className={`d-flex justify-content-center block-left-scroll ${isVisible ? 'visible' : ''}`}>
                    <div className="contact-reason-block">
                        <div className="hero-text-1 mt-0">
                            Take the first step toward customized IT solutions.
                        </div>
                        <Row>
                            {infoCards.map((item, index) => {
                                return (
                                    <Col key={index} xl="6" lg="4" md="6" className="contact-hooks mt-4">
                                        <div className="contact-icon">{item.icon}</div>
                                        <div className="large-text">{item.text}</div>
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                </Col>

                <Col xl="6" lg="12" className={`d-flex justify-content-center block-right-scroll ${isVisible ? 'visible' : ''}`} >
                    <div className="contact-form">
                        <div className="hero-text-1 contact-form-title">
                            Let's talk
                        </div>
                        <div className="hero-text-3 contact-subheading-block">
                            Want to get started on your IT transformation? <br />Let’s connect today.
                        </div>
                        <div className="contact-form-fields">
                            <div className="contact-label">
                                First name*
                            </div>
                            <div>
                                <input type="text" className="contact-input" maxLength={50} />
                            </div>
                            <div className="contact-label mt-2">
                                Last name*
                            </div>
                            <div>
                                <input type="text" className="contact-input" maxLength={50} />
                            </div>
                            <div className="contact-label mt-2">
                                Email*
                            </div>
                            <div>
                                <input type="text" className="contact-input" maxLength={50} />
                            </div>
                            <div className="contact-label contact-label mt-2">
                                How did you hear about us?*
                            </div>
                            <div>
                                <input type="text" className="contact-input" maxLength={100} />
                            </div>
                            <div className="contact-label mt-2">
                                Message*
                            </div>
                            <div>
                                <textarea rows="4" className="contact-input" maxLength={500} />
                            </div>
                        </div>
                        <div className="contact-submit">
                            <button className="submit-cta-button"> Submit</button>
                        </div>
                    </div>
                </Col>
            </Row>
        </div >
    )

}
export default ContactSection
