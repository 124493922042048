import React from 'react';
import '../../../assets/css/mainsite/heroSection.scss';
import { Col } from 'reactstrap';
import EditorGraphic from './editorGraphic';

const HeroSection = () => {
    return (
        <>
            <div className="hero-container">
                <div className="pattern-background"> </div>
                <div className="container-large ">
                    <div className="row">
                        {/* Heading */}
                        <Col xs="12" xl="12" className="text-center">
                            <h1 className="hero-text-1 pt-5">
                                Driving growth together with <br />
                                <span className="highlight">custom, scalable software</span>
                            </h1>
                        </Col>

                        {/* Code Editor Section */}
                        <Col xs="12" xl="12" className="d-flex justify-content-center align-items-center pt-4">
                            <EditorGraphic />
                        </Col>
                        <Col xs="12" className="text-center">
                            <div className="mt-5" >
                                <a href="https://outlook.office365.com/book/Mobyte1@MobyteLtd.onmicrosoft.com/" target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "black" }} >
                                    <button className="cta-button ">
                                        Schedule free call
                                    </button>
                                </a>
                            </div>
                        </Col>

                        {/* <Col xs="12">
                            <div className="homepage-h2"> We build tailored software solutions that empower businesses to grow, regardless of their size or IT complexity
                            </div>
                            <Row>
                                {icons.map((Icon, index) => {
                                    const randomSize = 5 // Random size between 1rem and 3rem

                                    return (
                                        <Col xs="2"
                                            key={index}
                                            className="icon-scatter"
                                            style={{
                                                fontSize: `${randomSize}rem`,
                                            }}
                                        >
                                            {Icon}
                                        </Col>
                                    );
                                })}

                            </Row>
                        </Col> */}
                    </div >
                </div>
            </div>

        </>

    );
};

export default HeroSection;
