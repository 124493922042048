import React, { useEffect } from 'react'
import ProcessItems from './processItems'
import TextOnImage from '../../shared/textOnImage'
import image from '../../../assets/images/image12.jpg'

const Process = () => {

    useEffect(() => {
        document.title = 'Process | Creating innovative IT solutions | Mobyte';

        // Create meta description
        const metaDescription = document.createElement('meta');
        metaDescription.setAttribute('name', 'description');
        metaDescription.content = 'Experience our meticulous software development process, guiding ideas to successful projects, from inception to deployment';

        // Create meta keywords
        const metaKeywords = document.createElement('meta');
        metaKeywords.setAttribute('name', 'keywords');
        metaKeywords.content = 'Ideation, requirements gathering, project planning, solution design, development, testing, deployment, support, maintenance, project management, software development process, project lifecycle';


        const metaRobots = document.createElement('meta');
        metaRobots.setAttribute('name', 'robots');
        metaRobots.content = 'index';
        document.head.appendChild(metaRobots);
        // Append both meta elements to the head
        document.head.appendChild(metaDescription);
        document.head.appendChild(metaKeywords);
        document.head.appendChild(metaKeywords);

        return () => {
            // Clean up added meta tags when component unmounts
            document.head.removeChild(metaDescription);
            document.head.removeChild(metaKeywords);
            document.head.removeChild(metaRobots);

        };
    }, []);
    const subheading = (
        <span className="hero-text-1 white-text">Our process</span>
    )

    return (
        <div className="">
            <TextOnImage imageUrl={image} heading={"Our Process"} subheading={subheading} fromHome={false} altText={"Team working around computer"} />
            <ProcessItems />
        </div>
    )
}

export default Process