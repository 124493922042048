import React, { useEffect } from 'react'
import { Row } from 'reactstrap'
import ServicesItems from "./servicesItems"
import TextOnImage from '../../shared/textOnImage'
import image from '../../../assets/images/services.jpg'

const Services = () => {

    useEffect(() => {
        document.title = 'Services | 35+ years expertise developing software | Mobyte';

        // Create meta description
        const metaDescription = document.createElement('meta');
        metaDescription.setAttribute('name', 'description');
        metaDescription.content = 'Discover our range of services : Software development and modernization, web design, data integration, database management and process automation.';

        // Create meta keywords
        const metaKeywords = document.createElement('meta');
        metaKeywords.setAttribute('name', 'keywords');
        metaKeywords.content = 'Software development, web design, data integration, software modernization, database management, automation, business applications, responsive websites, data consolidation, technology updates, process automation';

        const metaRobots = document.createElement('meta');
        metaRobots.setAttribute('name', 'robots');
        metaRobots.content = 'index';
        document.head.appendChild(metaRobots);

        // Append both meta elements to the head
        document.head.appendChild(metaDescription);
        document.head.appendChild(metaKeywords);
        document.head.appendChild(metaRobots);

        return () => {
            // Clean up added meta tags when component unmounts
            document.head.removeChild(metaDescription);
            document.head.removeChild(metaKeywords);
            document.head.removeChild(metaRobots);

        };


    }, []);

    const subheading = (
        // <span>
        //     Providing&nbsp;&nbsp;solutions for over 35 years
        // </span>
        // <span className="header-brush">Services</span>
        <span className="hero-text-1 white-text">Services</span>
    )



    return (
        <div className="mb-5">

            <TextOnImage imageUrl={image} heading={"Services"} subheading={subheading} fromHome={false} altText={"Laptop displaying code editor"} />

            <div className="container-large">
                <div className='heading-block'>
                    <h2 className={` hero-text-2 secondary-text `}>
                        Over 35 years expertise developing<span className={"accent-1-text emph-brush"}> cutting-edge software</span>
                    </h2>
                </div>
                <Row className="text-center ">

                    <ServicesItems />

                </Row>

            </div>

        </div >
    )
}

export default Services