import React, { useEffect, useState } from 'react';
import { IoArrowBack } from "react-icons/io5";
import TextOnImage from '../../shared/textOnImage'
import image from '../../../assets/images/cables.jpg'
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import base from '../../../services/naBaseService'
import { useParams } from 'react-router-dom';

const Bespoke = () => {

    const [blogDetail, setBlogDetail] = useState([])
    const [blog, setBlog] = useState([])
    const { blogId } = useParams();

    useEffect(() => {
        document.title = 'Custom software development | Mobyte';

        // Create meta description
        const metaDescription = document.createElement('meta');
        metaDescription.setAttribute('name', 'description');
        metaDescription.content = 'Discover the advantages of custom software development. Explore how bespoke solutions offer greater efficiencies compared to off-the-shelf software.';

        // Create meta keywords
        const metaKeywords = document.createElement('meta');
        metaKeywords.setAttribute('name', 'keywords');
        metaKeywords.content = 'Custom software development, bespoke software development, bespoke systems, flexibile software, efficiency software, tailored software, cost-effective software';

        const metaRobots = document.createElement('meta');
        metaRobots.setAttribute('name', 'robots');
        metaRobots.content = 'index';
        document.head.appendChild(metaRobots);

        // Append both meta elements to the head
        document.head.appendChild(metaDescription);
        document.head.appendChild(metaKeywords);
        document.head.appendChild(metaKeywords);

        return () => {
            // Clean up added meta tags when component unmounts
            document.head.removeChild(metaDescription);
            document.head.removeChild(metaKeywords);
            document.head.removeChild(metaRobots);

        };
    }, []);

    const subheading = (
        <span className="hero-text-1 white-text">
            Custom software
        </span>
    )

    useEffect(() => {

        const promises = [
            base.getAll(`blog/qry/${blogId}`),
            base.getAll(`blog/detail/qry/${blogId}`),
        ];

        Promise.all(promises)
            .then(([res, detail]) => {
                setBlog(res.data);
                setBlogDetail(detail.data);
            });

    }, [blogId]);

    return (
        <>
            <TextOnImage imageUrl={image} subheading={subheading} fromHome={false} altText={"Connected cables"} />
            <div className="container-large">

                <Link to="/blog" >
                    <Button className="mt-3 button-white">
                        <div className="d-flex">
                            <div >
                                <IoArrowBack size={25} />
                            </div>
                            &nbsp;&nbsp;
                            <div style={{ paddingTop: "1px" }}>Blog</div>
                        </div>
                    </Button>

                </Link>
                <div className="">
                    <h2 className="hero-text-2">
                        {blog.heading}
                    </h2>
                </div>
                <div className="mb-5">
                    <p style={{ textAlign: "left" }} className="content-body">
                        {blog.subheading}
                    </p>
                    {blogDetail.map((item, index) => {
                        return (
                            <React.Fragment key={`blog_detail${index}`}>
                                <h3 className="blog-heading mt-5">
                                    {item.heading}
                                </h3>
                                <p style={{ textAlign: "left" }} className="content-body" dangerouslySetInnerHTML={{ __html: item.bodyText }}>

                                </p>
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>

        </>
    );
}

export default Bespoke;
