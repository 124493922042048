import React, { useState, useEffect } from "react";
import "../../../assets/css/mainsite/editorGraphic.scss";
import codeLines from "./editorCodeLines";


const EditorGraphic = () => {

    const [currentLine, setCurrentLine] = useState(0);
    const [currentCharIndex, setCurrentCharIndex] = useState(0);
    const [typingSpanIndex, setTypingSpanIndex] = useState(0);
    const [displayedCode, setDisplayedCode] = useState([]);

    useEffect(() => {
        if (currentLine < codeLines.length) {
            const line = codeLines[currentLine];
            const span = line[typingSpanIndex];
            // Check if we are still typing the current span
            if (currentCharIndex < span.text.length) {

                const typingInterval = setTimeout(() => {
                    setDisplayedCode((prev) => {
                        // Make a shallow copy of previous state
                        const updatedCode = [...prev];

                        // Ensure the current line exists in the state (initialize if necessary)
                        if (!updatedCode[currentLine]) {
                            updatedCode[currentLine] = [];
                        }

                        // Ensure the current span for the current line exists and initialize it if necessary
                        if (!updatedCode[currentLine][typingSpanIndex]) {
                            updatedCode[currentLine][typingSpanIndex] = { text: '', className: span.className };
                        }

                        // Update the span with the newly typed character
                        updatedCode[currentLine][typingSpanIndex] = {
                            text: updatedCode[currentLine][typingSpanIndex].text + span.text[currentCharIndex],
                            className: span.className,
                        };

                        return updatedCode;
                    });
                    // Move to the next character
                    setCurrentCharIndex((prev) => prev + 1);
                }, 15); // Typing speed in ms
                return () => clearTimeout(typingInterval);
            } else {
                // If the current span is finished, move to the next span
                if (typingSpanIndex < line.length - 1) {
                    setTypingSpanIndex((prev) => prev + 1);
                    setCurrentCharIndex(0); // Reset char index for the next span
                } else {
                    // If all spans in the current line are completed, move to the next line
                    setCurrentLine((prev) => prev + 1);
                    setTypingSpanIndex(0); // Reset span index for the next line
                    setCurrentCharIndex(0); // Reset character index for the next line
                }
            }
        }
    }, [currentLine, currentCharIndex, typingSpanIndex]);






    return (
        <div className="graphic-container">
            <div className="editor">
                <div className="tabs">
                    <span className="tab active">index.js</span>
                </div>

                <div className="editor-body">
                    <div className="line-numbers">
                        {Array.from({ length: codeLines.length }, (_, i) => (
                            <div key={i}>{i + 1}</div>
                        ))}
                    </div>

                    <div className="code-block">
                        {displayedCode.map((line, idx) => (
                            idx !== currentLine && (
                                <div className="line" key={idx}>
                                    {line.map((span, spanIdx) => (
                                        <span key={spanIdx} className={span.className}>
                                            {span.text}
                                        </span>
                                    ))}
                                </div>
                            )
                        ))}

                        {currentLine < codeLines.length && (
                            <div className="line">
                                {displayedCode[currentLine]?.map((span, spanIdx) => (
                                    <span key={spanIdx} className={span.className}>
                                        {span.text}
                                    </span>
                                ))}
                                <span className="cursor blink">|</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditorGraphic;