import React, { useState, useEffect } from 'react'
import { Row, Col, Card } from 'reactstrap'
import { Link } from 'react-router-dom'
import imgT4 from '../../../assets/images/04-thumbnail.jpg'
import simg_LW from '../../../assets/images/profile.jpg'
import simg_KM from '../../../assets/images/kmcnulty.jpeg'
import TextOnImage from '../../shared/textOnImage'
import image from '../../../assets/images/cables.jpg'
import base from '../../../services/naBaseService'
import '../../../assets/css/mainsite/blog.scss'

const Blog = () => {
    const [blogs, setBlogs] = useState([])

    useEffect(() => {
        document.title = 'Blog | Mobyte';

        // Create meta description
        const metaDescription = document.createElement('meta');
        metaDescription.setAttribute('name', 'description');
        metaDescription.content = 'Explore insightful articles and expert opinions on the latest software development trends, tips, and best practices.';

        // Create meta keywords
        const metaKeywords = document.createElement('meta');
        metaKeywords.setAttribute('name', 'keywords');
        metaKeywords.content = 'Software blog, Software development tips';

        const metaRobots = document.createElement('meta');
        metaRobots.setAttribute('name', 'robots');
        metaRobots.content = 'index';
        document.head.appendChild(metaRobots);

        // Append both meta elements to the head
        document.head.appendChild(metaDescription);
        document.head.appendChild(metaKeywords);
        document.head.appendChild(metaKeywords);

        return () => {
            // Clean up added meta tags when component unmounts
            document.head.removeChild(metaDescription);
            document.head.removeChild(metaKeywords);
            document.head.removeChild(metaRobots);

        };
    }, []);

    const subheading = (
        <span className="hero-text-1 white-text">
            Blog
        </span>
    )

    useEffect(() => {
        base.getAll("blog/qry").then((res) => {
            setBlogs(res.data);
        });
    }, []);

    return (
        <div className="mb-5">


            <TextOnImage imageUrl={image} heading={"About us"} subheading={subheading} fromHome={false} altText={"Connected cables"} />

            <div className="container-large">
                <div className='heading-block'>
                    <h2 className={`hero-text-2 secondary-text`}>
                        Stay updated with the latest in tech and tutorials from <span className="header-brush">IT experts</span>&nbsp;
                    </h2>
                </div>
                <Row>
                    {blogs.map((item, index) => {

                        const author = item.author
                        let authorImg;
                        switch (author) {
                            case 'Lawrence Whale':
                                authorImg = simg_LW
                                break;
                            case 'Katherine McNulty':
                                authorImg = simg_KM
                                break;
                            default:
                                break;
                        }

                        return (
                            <React.Fragment key={`blog_${index}`}>
                                {item.display === true ?
                                    <>
                                        <Col xl="4" lg="6" md="6" sm="12" xs="12" className="mb-4 "  >
                                            <Card >
                                                <div className="">
                                                    <img className="img-fluid" src={imgT4} alt="Laptop displaying interactive dashboard" />
                                                </div>
                                                <Col className="p-3">
                                                    <Row className="p-1">
                                                        <Col>
                                                            <div className="portfolio-caption-heading text-left">{item.heading}</div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="p-1">
                                                        <Col style={{ textAlign: "left" }} className="sub-text">
                                                            {item.subheading.substring(0, 237)}...
                                                            <Link to={`/bespoke/${item.id}`} >
                                                                read more
                                                            </Link>
                                                        </Col>
                                                    </Row>
                                                    <Row className="p-2">
                                                        <Col xs="2">
                                                            <div className="circular--author"> <img src={authorImg} alt="Author" /> </div>
                                                        </Col>
                                                        <Col xs="10">
                                                            <Row>
                                                                <Col className="portfolio-caption-subheading text-left">
                                                                    {item.author}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <div className="portfolio-caption-subheading text-left">{item.post_date.substring(0, 10)}</div>
                                                                </Col>
                                                                <Col>
                                                                    <div className="portfolio-caption-subheading text-left">{item.read_time}</div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Card>
                                        </Col>
                                    </>
                                    :
                                    ""
                                }
                            </React.Fragment>
                        );
                    })}

                </Row>

            </div >
        </div >
    )
}

export default Blog