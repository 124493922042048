import React from 'react'
import { useRef, useState, useEffect } from 'react';
import { FaCheckCircle } from 'react-icons/fa'
import { Row, Col } from 'reactstrap'
import ReCAPTCHA from "react-google-recaptcha";
import base from '../../../services/naBaseService'
import Loading from '../../shared/loading'
import TextOnImage from '../../shared/textOnImage'
import image from '../../../assets/images/contact_phone.jpg'


const Contact = () => {
    const recaptchaRef = useRef("");
    const [pName, setName] = useState("")
    const [nameIsValid, setNameIsValid] = useState(false)
    const [pEmail, setEmail] = useState("")
    // const [org, setOrg] = useState("")
    const [emailIsValid, setEmailIsValid] = useState(false)
    const [pMessage, setMessage] = useState("")
    const [messageIsValid, setMessageIsValid] = useState(false)
    // const [orgIsValid, setOrgIsValid] = useState(false)
    const [sendingMessage, setSendingMessage] = useState(false)
    const [submitForm, setSubmitForm] = useState(false);
    const [signError, setSignError] = useState(false);
    const contactData = {};


    function onChange(value) {
        setSignError(false);
    }

    useEffect(() => {
        document.title = 'Contact us | Mobyte';
    }, []);

    const onSubmitWithReCAPTCHA = async () => {
        if ((nameIsValid && emailIsValid && messageIsValid)) {
            const token = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.execute();
            if (token !== "") {
                setSendingMessage(true);
                // console.log("here")
                contactData.name = pName;
                contactData.email = pEmail;
                contactData.comment = pMessage;
                base.add("Contact/add/", contactData).then((res) => {
                    console.log(res);
                    if (res.status === 200) { // Check if the response status is 200
                        setSubmitForm(true);
                        setSignError(false);
                        setSendingMessage(false);
                    } else {
                        // Handle error or non-200 response
                        setSendingMessage(false);
                    }
                })
            }
            else {
                setSignError(true);
            }
        }
        else {
            if (!nameIsValid) {
                document.getElementById("fullname").style.border = '2px solid #ff0000';
            }
            if (!emailIsValid) {
                document.getElementById("emailid").style.border = '2px solid #ff0000';
                // //console.log(pEmail.trim().length, pEmail)
            }
            if (!messageIsValid) {
                document.getElementById("message").style.border = '2px solid #ff0000';
            }
            // if (!orgIsValid) {
            //     document.getElementById("org").style.border = '2px solid #ff0000';
            // }
        }

    }

    const handleChange = (e) => {

        // var sanitizedValue = sanitizeInput(event.target.name, event.target.value)
        // var inputValue= e.target.value;
        var sanitizedValue = ""



        switch (e.target.name) {
            case "emailid":
                // console.log("here eamil")
                sanitizedValue = e.target.value.replace(/[^\w.@_+\-=]/gi, '');
                break;
            default:
                // console.log("here default")
                sanitizedValue = e.target.value.replace(/[^\w\s?,.]/gi, '');
        }
        // console.log(sanitizedValue, e.target.value)


        if (e.target.name === 'fullname') {
            setName(sanitizedValue);
            document.getElementById("fullname").style.border = '1px solid #cecece';
            if (sanitizedValue.trim().length < 1) {
                setNameIsValid(false);
            }
            else {
                setNameIsValid(true);

            }
        }

        // if (e.target.name === 'org') {
        //     setOrg(sanitizedValue);
        //     document.getElementById("org").style.border = '1px solid #cecece';
        //     if (sanitizedValue.trim().length < 1) {
        //         setOrgIsValid(false);
        //     }
        //     else {
        //         setOrgIsValid(true);
        //     }
        // }

        if (e.target.name === 'emailid') {
            setEmail(sanitizedValue)
            document.getElementById("emailid").style.border = '1px solid #cecece';
            if (sanitizedValue.trim().length < 1) {
                setEmailIsValid(false)
            }
            else {
                var reg = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
                if (!reg.test(e.target.value)) {
                    setEmailIsValid(false)

                }
                else {
                    setEmailIsValid(true)
                }
            }
        }
        if (e.target.name === 'message') {
            setMessage(sanitizedValue)
            document.getElementById("message").style.border = '1px solid #cecece';
            if (sanitizedValue.trim().length < 1) {
                setMessageIsValid(false);
            }
            else {
                setMessageIsValid(true);
            }

        }
    }

    const subheading = (
        <span className='hero-text-1 white-text'>
            Contact us
        </span>
    )


    return (
        <div className="" >

            <TextOnImage imageUrl={image} heading={"Contact us"} altText={"Person using mobile phone with blurred laptop in background"} subheading={subheading} fromHome={false} />

            <div className="container-large">
                <div className='heading-block'>
                    <h2 className={`hero-text-2 secondary-text`}>
                        For inquiries about our <span className="header-brush">software development services</span>
                    </h2>
                </div>
                <Row className="pb-3 ">
                    <Col lg="12" xl="12" >
                        {sendingMessage ?
                            <div className="text-center ms-5 ps-5 me-5 pe-5 pb-1 pt-5" style={{ marginBottom: "127px" }}>
                                <Loading />
                                <br /><br />
                                <div className="text-center text-muted">Submitting message...</div>
                                <br />
                                <div className="text-center text-muted"></div>
                            </div>
                            :
                            !submitForm ?
                                <>
                                    <div className="pb-4">
                                        <div className="form-group">
                                            <label htmlFor="fullname">Full Name*</label>
                                            <input id="fullname" name="fullname" type="text" className="form-control" value={pName} onChange={handleChange} maxLength={75} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="email">Email*</label>
                                            <input id="emailid" name="emailid" type="email" className="form-control" value={pEmail} aria-describedby="emailHelp" onChange={handleChange} maxLength={200} />
                                        </div>
                                        {/* <div className="form-group">
                                            <label htmlFor="email">Organistaion*</label>
                                            <input id="org" name="org" type="org" className="form-control" value={org} aria-describedby="emailHelp" onChange={handleChange} maxLength={75} />
                                        </div> */}
                                        <div className="form-group" >
                                            <label htmlFor="message">Message*</label>
                                            <textarea id="message" name="message" style={{ minHeight: "135px" }} value={pMessage} className="form-control" rows="4" onChange={handleChange} maxLength={1000}></textarea>
                                        </div>
                                        <br />
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            sitekey="6LfySsMkAAAAAB59dcb-8NpKaPvk5urB97em28hl"
                                            onChange={onChange}
                                            size="invisible"
                                        />

                                        <div>
                                            By submitting this form you confirm that you agree to Mobyte's <a href="https://www.mobyte.com/privacy" target="_blank" rel="noreferrer">privacy policy</a>
                                        </div>
                                        <br />

                                        {signError &&
                                            <>
                                                <div className="text-danger">Please sign reCAPTCHA</div>
                                                <br />
                                            </>
                                        }
                                        <button type="submit" className="contact-cta-button"
                                            onClick={() => onSubmitWithReCAPTCHA()}
                                        >Submit</button>
                                    </div>
                                </>
                                :

                                <div className="ms-5 ps-5 me-5 pe-5 pb-1 pt-5" style={{ marginBottom: "127px" }}>
                                    <div className="text-center fade-in " >
                                        <FaCheckCircle size={100} color="#0275d8" />
                                    </div>
                                    <br /><br />
                                    <div className="text-center text-muted">Your message has been submitted.</div>
                                    <br />
                                    <div className="text-center text-muted">We will be in touch soon!</div>
                                </div>
                        }
                    </Col>
                </Row >

            </div>
            <div className="container-large">
                {/* <Row className=""> */}
                <div className="hero-text-2 secondary-text pb-3">
                    Where to find us
                </div>
                {/* </Row > */}
            </div>
            <div style={{ overflow: "hidden !important" }}>
                <iframe title="mapview" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7525.723683513492!2d0.13891268179001015!3d51.78305096805384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d89bcc9a039051%3A0xbe668c40d6a723ed!2sMobyte%20Ltd!5e0!3m2!1sen!2suk!4v1586417867820!5m2!1sen!2suk" style={{ height: "450px", width: "100%", frameBorder: "0" }}></iframe>
            </div >
        </div >
    )
}

export default Contact