import React from 'react'
import { useEffect, useState } from 'react';
import { Col, Row, Button, Label, Input, Modal, ModalBody, ModalHeader, FormGroup, ModalFooter } from 'reactstrap';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import base from '../../../services/BaseService'
import LoadSpinner from '../../shared/loading'

const TasksModal = ({ id, closeModal, showModalForm, setSaveItem }) => {

    const [field01, setField01] = useState("")
    const [field02, setField02] = useState("")
    const [field03, setField03] = useState("")
    const [field04, setField04] = useState("")
    const [field05, setField05] = useState("")
    const [field06, setField06] = useState("")
    const [field07, setField07] = useState("")
    // const [field08, setField08] = useState()
    const [field09, setField09] = useState()
    const [check09, setCheck09] = useState(false)
    const [field10, setField10] = useState("")
    // const [check10, setCheck10] = useState()
    const [field11, setField11] = useState("")
    // const [field12, setField12] = useState()
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(showModalForm)
    const updateData = {};

    const toggleModal = () => {
        setShowModal(showModal => !showModal);

        closeModal()
    }

    const updateItem = () => {
        updateData.url = field01;
        updateData.missing = field09;
        updateData.access = field10;
        updateData.comment = field11;

        base.update("Report/vat/upd/" + id, updateData).then((res) => {
            setSaveItem(new Date().getTime());
            toggleModal()
        })
        // alert("Save!")
    }

    const handleChange = (e) => {
        // //console.log("hangleChange", e.target.name)
        // if (e.target.name === 'url') {
        //     setField01(e.target.value);
        //     // setDescriptionRemaining(e.target.value.length);
        // }
        if (e.target.name === 'date') {
            setField02(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'name') {
            setField03(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'description') {
            setField04(e.target.value);
            // //console.log(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'tax_rate') {
            setField05(e.target.value);
            // //console.log(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'gross_value') {
            setField06(e.target.value);
            // //console.log(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'sales_tax_value') {
            setField07(e.target.value);
            // //console.log(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'comment') {
            setField11(e.target.value);
            // //console.log(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'urlADO') {
            setField10(e.target.value);
            // console.log(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'missing') {

            if (e.target.checked) {
                // console.log("Y");
                setField09(1);
                setCheck09(true)
            }
            else {
                // console.log("N");
                setField09(0);
                setCheck09(false)
            }

            // setField09(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        // if (e.target.name === 'access') {

        //     if (e.target.checked) {
        //         // console.log("Y");
        //         setField10(1);
        //         setCheck10(true)
        //     }
        //     else {
        //         // console.log("N");
        //         setField10(0);
        //         setCheck10(false)
        //     }

        // }


        document.getElementById(e.target.id).style.border = '1px solid #cecece';
    }

    useEffect(() => {
        const getData = () => {
            // if (editExisting) {
            // setDisableUPN(dU => true)
            // console.log(id);
            const getFormData = async () => {
                await base.getAll("Report/vat/" + id).then((res) => {
                    setField01(res.url);
                    setField02(res.dated_on);
                    setField03(res.name);
                    setField04(res.description);
                    setField05(res.tax_rate);
                    setField06(res.gross_value);
                    setField07(res.sales_tax_value);
                    // setField08(res.missing);
                    // setField09(res.access);
                    setField11(res.comment);
                    // setField12(res.te_id)
                    // console.log(res.comment);
                    if (res.missing === 1) {
                        setCheck09(true);
                        setField09(1);

                    }
                    else {
                        setCheck09(false);
                        setField09(0);

                    }
                    if (res.access === 1) {
                        setField10(1);
                        // setCheck10(true);
                    }
                    else {
                        setField10(0);
                        // setCheck10(false);
                    }
                    setLoading(false)
                })
            }

            getFormData();
            // }
            // else {
            //     // setDisableUPN(dU => false)
            //     setLoading(false)
            // }
        }
        getData()
    }, [id])


    return (
        <>
            <Modal isOpen={showModal} size="lg" toggle={toggleModal} backdrop="static">
                <ModalHeader className="bg-modal" toggle={toggleModal}><div className="page-header">Edit Transaction</div></ModalHeader>
                {loading ?
                    <LoadSpinner />
                    :
                    <>
                        <ModalBody>
                            <FormGroup>
                                {/* <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text" >URL</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" disabled className="input-text-locked" name="url" id="url" placeholder="" defaultValue={field01} onChange={handleChange} />
                                    </Col>
                                </Row> */}
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Date</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" disabled className="input-text-locked" name="date" id="date" placeholder="" defaultValue={field02} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Name</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" disabled className="input-text-locked" name="name" id="name" placeholder="" defaultValue={field03} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Description</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" disabled className="input-text-locked" name="description" id="description" placeholder="" defaultValue={field04} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">tax_rate</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" disabled className="input-text" name="tax_rate" id="tax_rate" placeholder="" value={field05} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">gross_value</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" disabled className="input-text" name="gross_value" id="gross_value" placeholder="" value={field06} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">sales_tax_value</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" disabled className="input-text" name="sales_tax_value" id="sales_tax_value" placeholder="" value={field07} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Missing</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="checkbox" checked={check09} name="missing" id="missing" className='input-check' onChange={handleChange} >
                                        </Input>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Access</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="checkbox" checked={check10} name="access" id="access" className='input-check' onChange={handleChange} >
                                        </Input>
                                    </Col>
                                </Row> */}
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Comment</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" maxLength={75} className="input-text" name="comment" id="comment" placeholder="" value={field11} onChange={handleChange} />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success" size="sm" className="btn-text b-width" onClick={() => updateItem()}>Save</Button>
                            <Button color="secondary" size="sm" className="btn-text b-width" onClick={toggleModal}>Cancel</Button>
                        </ModalFooter>
                    </>
                }
            </Modal>
        </>
    )
}

export default TasksModal
