import { IoStar } from "react-icons/io5";
import '../../../assets/css/mainsite/testimonial.scss';


const testimonials = [
    {
        name: 'Daniel Cullen',
        role: 'Finance Director, FlowServe',
        content: 'Working with Mobyte was a game-changer for our business. Their solutions helped us scale efficiently and stay ahead of competition.',
    },
    {
        name: 'Nick Thompson',
        role: 'Former Global Training Manager, Air bp',
        content: 'I cannot recommend Mobyte enough. From the outset the project felt almost insurmountable, but what Mobyte delivered was of such high quality. Their ability to interpret business requirements and to overcome challenges was first class.',
    },
    {
        name: 'Mat Carmody',
        role: 'Finance Strategy & Process Manager, bp',
        content: 'The team at Mobyte consistently delivers exceptional results. Their attention to detail and innovative approach sets them apart.',
    },
    {
        name: 'Michael Moore',
        role: 'Head of Finance, Skyline Green',
        content: 'Mobyte truly understands our needs and always has our best interests at heart. Their dedication to delivering solutions that drive our success is unparalleled"',
    }
];

export default function Testimonials() {
    return (
        <div className="container-large pb-5 pt-5">
            <div className="hero-text-1 white-text pb-4">
                What our customers say
            </div>
            <div className="testimonials-grid pt-3">
                {testimonials.map((testimonial, index) => (
                    <div key={index} className="testimonial-card">
                        <div className="testimonial-header">
                            <div>
                                <h3 className="testimonial-name">{testimonial.name}</h3>
                                <p className="testimonial-role">{testimonial.role}</p>
                            </div>
                        </div>
                        <div className="testimonial-rating">
                            {[...Array(5)].map((_, i) => (
                                <IoStar key={i} className="star-icon" />
                            ))}
                        </div>
                        <p className="standard-text">{testimonial.content}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}
