import React from 'react'
import image from '../../assets/images/cables.jpg'
import TextOnImage from '../shared/textOnImage'
import { IoBan } from 'react-icons/io5'
import { Row, Col } from 'reactstrap'

const AccessDenied = () => {

    const subheading = (
        <span className="hero-text-1 white-text">
            Restricted Content
        </span>
    )

    return (
        <div className="">
            <TextOnImage imageUrl={image} heading={"Restricted Content"} subheading={subheading} fromHome={false} altText={"Connected cables"} />

            <Row className="container-large pt-4 pb-5">
                <Col xs="4" className="text-center block-left-visible">
                    <IoBan className="icon-large mb-3" />
                </Col>
                <Col className="block-right-visible">
                    <h2 className={`hero-text-2 secondary-text`}>
                        <span>Access Denied</span>
                    </h2>
                    <p className={`content-body secondary-text`}>
                        You do not have permission to access this page.
                        <br />
                        Please contact the site administrator for more information.
                    </p>

                </Col>
            </Row>
        </div >
    )
}

export default AccessDenied