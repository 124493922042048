import React from 'react';
import { AiOutlineCode, AiOutlineApi, AiOutlineSetting, AiOutlineDatabase, AiOutlineAppstoreAdd } from 'react-icons/ai';
import { IoArrowUpSharp, IoLogoReact, IoSaveOutline, IoShieldCheckmarkOutline, IoSwapHorizontalOutline } from 'react-icons/io5';
import { IoShirtOutline, IoPower, IoScaleOutline, IoLockOpenOutline, IoChatbubbleEllipsesOutline } from 'react-icons/io5'
import { AiOutlineLineChart, } from 'react-icons/ai'

const iconColor = '#1F81D6';

const servicesData = [
    {
        id: "softwaredevelopment",
        icon: <AiOutlineAppstoreAdd size={100} color={iconColor} />,
        heading: "Software development",
        subHeading:
            <span >
                <span className="hero-text-1 white-text">Software development</span>&nbsp;&nbsp;
                {/* that empower your business */}
            </span>
        ,
        description: "Developing software that is designed to support various business processes. Business applications are used by organizations to automate and streamline processes, improve efficiency, and make better informed decisions.",
        keywords: "Software Development, Custom Software Development, Software Development Services, Business Software Development, Enterprise Software Development, Software Solutions, Software Development Company, Application Development, Custom Software Solutions, Software Engineering, Custom Software Development for Businesses, Enterprise Application Development, Bespoke Software Development Services, End-to-End Software Development Solutions, Scalable Software Solutions for Enterprises, Agile Software Development, Software Development Lifecycle, Full-Stack Software Development, Software Development Outsourcing, Software Modernization",
        content: [{
            id: 1,
            heading: <h3 className="hero-text-2 secondary-text">Empower your business with <span className="hero-text-2-emphasis accent-1-text emph-brush">custom software solutions</span></h3>,
            subheading: "In today's dynamic business landscape, staying ahead of the competition requires more than off-the-shelf software solutions. At Mobyte, we understand the unique challenges your business faces and the critical role that software plays in driving growth, efficiency, and innovation.",
            icon: <IoPower className='icon-large icon-right mb-3 ' />
        },
        {
            id: 2,
            heading: <h3 className="hero-text-2 white-text"><span className="hero-text-2-emphasis emph-brush">Tailored solutions</span> for your unique needs</h3>,
            subheading: "Off-the-shelf software may offer convenience, but it often falls short when it comes to addressing your specific business requirements. That's where custom software development shines. Our team of experienced developers works closely with you to understand your business processes, challenges, and goals. We then design and build custom software solutions that are tailored to your exact needs, ensuring seamless integration with your existing systems and workflows.",
            icon: <IoShirtOutline className='icon-large  mb-3' />
        },
        {
            id: 3,
            heading: <h3 className="hero-text-2 white-text"><span className="hero-text-2-emphasis accent-1-text emph-brush">Scalable</span> to future proof your business</h3>,
            subheading: "One of the key advantages of custom software is scalability. As your business evolves and expands, your software should be able to grow with it. Our custom solutions are designed with scalability in mind, allowing you to easily add new features, accommodate increased user loads, and adapt to changing market demands. With our future-proof software, you can stay ahead of the curve and drive sustainable business growth.",
            icon: <IoScaleOutline className='icon-large  mb-3 white-text' />
        },
        {
            id: 4,
            heading: <h3 className="hero-text-2 secondary-text">Enhanced <span className="hero-text-2-emphasis accent-1-text emph-brush">efficiency and productivity</span></h3>,
            subheading: "Time is money, and inefficient processes can eat into your bottom line. Our custom software solutions are engineered to streamline your workflows, automate repetitive tasks, and eliminate bottlenecks. By optimizing your business processes, you can boost productivity, reduce operational costs, and free up valuable resources to focus on what matters most: driving your business forward.",
            icon: <AiOutlineLineChart className='icon-large icon-right mb-3 ' />
        },
        {
            id: 5,
            heading: <h3 className="hero-text-2 white-text">Seamless <span className="hero-text-2-emphasis emph-brush">integration and support</span></h3>,
            subheading: "We understand that transitioning to new software can be daunting. That's why we offer comprehensive support throughout the entire process, from initial consultation to deployment and beyond. Our team ensures seamless integration with your existing systems and provides ongoing support and maintenance to keep your software running smoothly. With Mobyte by your side, you can trust that your software is in good hands.",
            icon: <IoChatbubbleEllipsesOutline className='icon-large  mb-3' />
        },
        {
            id: 6,
            heading: <h3 className="hero-text-2 white-text">Unlock your <span className="hero-text-2-emphasis accent-1-text emph-brush">business potential</span></h3>,
            subheading: "Don't let outdated or generic software hold your business back. Partner with Mobyte and unlock your full potential with custom software solutions designed to meet your unique needs. Whether you're looking to streamline operations, drive innovation, or gain a competitive edge, we're here to help you succeed.",
            icon: <IoLockOpenOutline className='icon-large  mb-3 white-text' />
        }
        ]
    },
    // {
    //     id: "webdesign",
    //     icon: <AiOutlineCode size={100} color={iconColor} />,
    //     heading: "Web design",
    //     subHeading:
    //         <span >
    //             <span className="hero-text-1 white-text">Web design</span>&nbsp;&nbsp;
    //             {/* that empower your business */}
    //         </span>
    //     ,
    //     description: "Creating responsive websites, including layout, colors, typography, and visual elements, to provide an optimal user experience. We combine technical skills with design principles to create functional and aesthetically pleasing websites.",
    //     keywords: "Web Design, Responsive Web Design, Web Design Services, Professional Web Design, Custom Web Design, Website Design, Web Design Company, UI/UX Design, Website Redesign, E-commerce Web Design, Responsive Web Design Services, Professional Website Design for Businesses, Custom Web Design Solutions, User-Centric Web Design Services, E-commerce Website Design and Development, Graphic Design, User Interface Design, User Experience Design, Mobile-Friendly Web Design, Website Optimization",
    //     content: [{
    //         id: 1,
    //         heading: <h3 className="hero-text-2 secondary-text">Elevate your online presence with <span className="hero-text-2-emphasis accent-1-text emph-brush">custom web design</span></h3>,
    //         subheading: "In today's digital age, your website is often the first impression customers have of your business. At Mobyte, we recognize the importance of a captivating and user-friendly web presence in driving engagement, conversions, and brand loyalty.",
    //         icon: <IoArrowUpSharp className='icon-large icon-right mb-3 ' />
    //     },
    //     {
    //         id: 2,
    //         heading: <h3 className="hero-text-2 white-text"><span className="hero-text-2-emphasis emph-brush">Customized designs</span> for your brand identity</h3>,
    //         subheading: "Off-the-shelf website templates may offer convenience, but they lack the uniqueness and personalization that sets your brand apart. Our team of talented designers specializes in creating custom web designs tailored to your brand identity, audience, and business objectives. From color schemes and typography to layout and imagery, we craft visually stunning and cohesive designs that reflect your brand's personality and values.",
    //         icon: <IoColorPaletteSharp className='icon-large  mb-3' />
    //     },
    //     {
    //         id: 3,
    //         heading: <h3 className="hero-text-2 white-text"><span className="hero-text-2-emphasis accent-1-text emph-brush">Mobile-Friendly</span></h3>,
    //         subheading: "With the rise of mobile browsing, it's essential that your website looks and functions flawlessly across all devices and screen sizes. Our responsive web designs adapt seamlessly to desktops, tablets, and smartphones, ensuring an optimal viewing experience for every visitor. By prioritizing mobile-friendliness, we help you reach and engage with your audience wherever they are.",
    //         icon: <IoPhonePortraitSharp className='icon-large  mb-3 white-text' />
    //     },
    //     {
    //         id: 4,
    //         heading: <h3 className="hero-text-2 secondary-text"><span className="hero-text-2-emphasis accent-1-text emph-brush">SEO optimised</span> for online visability</h3>,
    //         subheading: "A stunning website is only effective if it can be found by your target audience. Our web design services include SEO optimization to improve your site's visibility in search engine results. From optimized content and metadata to site speed and mobile-friendliness, we implement best practices to enhance your website's search engine ranking and attract organic traffic.",
    //         icon: <AiOutlineSearch className='icon-large icon-right mb-3 ' />
    //     },
    //     {
    //         id: 5,
    //         heading: <h3 className="hero-text-2 white-text">Comprehensive <span className="hero-text-2-emphasis emph-brush">support and maintenance</span></h3>,
    //         subheading: "Launching a website is just the beginning of your online journey. Our team provides ongoing support and maintenance to ensure your website remains secure, up-to-date, and performing at its best. Whether you need content updates, security patches, or technical assistance, we're here to support you every step of the way.",
    //         icon: <IoChatbubbleEllipsesOutline className='icon-large  mb-3' />
    //     },
    //     {
    //         id: 6,
    //         heading: <h3 className="hero-text-2 white-text">Unlock your <span className="hero-text-2-emphasis accent-1-text emph-brush">online potential</span></h3>,
    //         subheading: "Don't settle for a generic or outdated website. Partner with Mobyte and unlock your online potential with innovative web design solutions tailored to your business goals. Whether you're launching a new site, refreshing an existing one, or embarking on an e-commerce venture, we're here to turn your vision into reality.",
    //         icon: <IoLockOpenOutline className='icon-large  mb-3 white-text' />
    //     }
    //     ]
    // },
    {
        id: "apidevelopment",
        icon: <AiOutlineCode size={100} color={iconColor} />,
        heading: "API development",
        subHeading:
            <span >
                <span className="hero-text-1 white-text">API development</span>&nbsp;&nbsp;
                {/* that empower your business */}
            </span>
        ,
        description: "Developing robust APIs to enhance functionality and integration across various platforms. We combine technical expertise with industry best practices to create secure, efficient, and reliable APIs that drive business growth and innovation.",
        keywords: "API Development, Custom API Development, RESTful APIs, API Integration, API Design, API Management, Secure API Development, Scalable API Solutions, API Services, API Gateway, API Documentation, Professional API Development, Custom API Solutions, Business API Development, Efficient API Integration, Reliable API Services, API Security, API Performance Optimization, Developer-Friendly APIs, Enterprise API Solutions",
        content: [{
            id: 1,
            heading: <h3 className="hero-text-2 secondary-text">Elevate your business with <span className="hero-text-2-emphasis accent-1-text emph-brush">custom API development</span></h3>,
            subheading: "In today’s interconnected digital landscape, APIs are the backbone of seamless integration and innovation. At Mobyte, we understand the critical role APIs play in enhancing functionality, streamlining processes, and enabling new business opportunities.",
            icon: <IoArrowUpSharp className='icon-large icon-right mb-3 ' />
        },
        {
            id: 2,
            heading: <h3 className="hero-text-2 white-text"><span className="hero-text-2-emphasis emph-brush">Tailored API solutions</span> for your unique needs</h3>,
            subheading: "Off-the-shelf APIs may provide basic functionality, but they often fall short of meeting the specific needs of your business. Our team of experienced developers specializes in creating custom APIs tailored to your unique requirements, ensuring optimal performance and seamless integration with your existing systems. From design to deployment, we craft APIs that enhance your capabilities and drive growth.",
            icon: <IoShirtOutline className='icon-large  mb-3' />
        },
        {
            id: 3,
            heading: <h3 className="hero-text-2 white-text"><span className="hero-text-2-emphasis accent-1-text emph-brush">Seamless integration</span> with existing systems</h3>,
            subheading: "ntegrating new APIs with your existing systems can be complex and challenging. Our expertise in API integration ensures a smooth and hassle-free process, minimizing disruptions to your operations. We work closely with your team to understand your systems and develop APIs that seamlessly connect and enhance your technological ecosystem.",
            icon: <AiOutlineApi className='icon-large  mb-3 white-text' />
        },
        {
            id: 4,
            heading: <h3 className="hero-text-2 secondary-text"><span className="hero-text-2-emphasis accent-1-text emph-brush">Performance optimization</span> for efficiency</h3>,
            subheading: "A stunning website is only effective if it can be found by your target audience. Our web design services include SEO optimization to improve your site's visibility in search engine results. From optimized content and metadata to site speed and mobile-friendliness, we implement best practices to enhance your website's search engine ranking and attract organic traffic.",
            icon: <AiOutlineLineChart className='icon-large icon-right mb-3 ' />
        },
        {
            id: 5,
            heading: <h3 className="hero-text-2 white-text">Comprehensive<span className="hero-text-2-emphasis emph-brush"> documentation and support</span></h3>,
            subheading: "Clear and comprehensive documentation is essential for the successful adoption and use of APIs. We provide detailed documentation for all our APIs, making it easy for your developers to understand and integrate them. Additionally, our team offers ongoing support to address any issues and ensure the continued performance and reliability of your APIs.",
            icon: <IoChatbubbleEllipsesOutline className='icon-large  mb-3' />
        },
        {
            id: 6,
            heading: <h3 className="hero-text-2 white-text">Unlock your <span className="hero-text-2-emphasis accent-1-text emph-brush">business potential</span></h3>,
            subheading: "Don’t let generic solutions limit your potential. Partner with Mobyte to unlock new opportunities with custom API development tailored to your business needs. Whether you’re looking to integrate new services, enhance your existing capabilities, or drive innovation, we’re here to help you achieve your goals with cutting-edge API solutions.",
            icon: <IoLockOpenOutline className='icon-large  mb-3 white-text' />
        }
        ]
    },
    {
        id: "dataintegration",
        icon: <AiOutlineApi size={100} color={iconColor} />,
        heading: "Data integration",
        subHeading:
            <span >
                <span className="hero-text-1 white-text">Data integration</span>&nbsp;&nbsp;
                {/* that empower your business */}
            </span>
        ,
        description: "Combining data from multiple sources into a single, unified view. This involves the collection, transformation, and loading of data into a centralized repository, so that it can be analyzed and used to support business decisions.",
        keywords: "Data Integration, Data Integration Services, Enterprise Data Integration, Data Integration Solutions, Data Integration Tools, Data Integration Platform, Data Integration Company, Data Integration Software, Cloud Data Integration, Real-Time Data Integration, Comprehensive Data Integration Services, Enterprise Data Integration Solutions, Data Integration and Transformation, Seamless Data Integration Services, Data Integration for Business Intelligence, ETL (Extract, Transform, Load), Data Warehousing, Big Data Integration, Data Migration, Data Synchronization",
        content: [{
            id: 1,
            heading: <h3 className="hero-text-2 secondary-text">Streamline operations with <span className="hero-text-2-emphasis accent-1-text emph-brush"> data integration</span></h3>,
            subheading: "In today's data-driven world, seamless data integration is essential for operational efficiency and informed decision-making. At Mobyte, we understand the complexities of managing data from diverse sources and the critical role that integrated data plays in driving business success.",
            icon: <IoArrowUpSharp className='icon-large icon-right mb-3 ' />
        },
        {
            id: 2,
            heading: <h3 className="hero-text-2 white-text"><span className="hero-text-2-emphasis emph-brush">Customized integration solutions</span> for your unique needs</h3>,
            subheading: "Standard integration solutions often fail to address the unique requirements of your business. Our team of experts work closely with you to understand your specific data landscape, challenges, and objectives. We design and implement custom data integration solutions tailored to your needs, ensuring seamless connectivity between disparate systems and data sources.",
            icon: <AiOutlineApi className='icon-large  mb-3 ' />
        },
        {
            id: 3,
            heading: <h3 className="hero-text-2 white-text"><span className="hero-text-2-emphasis accent-1-text emph-brush">Unified data</span> for informed decisions</h3>,
            subheading: "Data silos can hinder your ability to make informed decisions and respond swiftly to market changes. Our data integration services consolidate data from multiple sources into a single, unified view, providing you with accurate and real-time insights. By breaking down data barriers, we help you unlock the full potential of your information and make data-driven decisions with confidence.",
            icon: <AiOutlineDatabase className='icon-large icon-right mb-3 white-text' />
        },
        {
            id: 4,
            heading: <h3 className="hero-text-2 ">Enhanced <span className="hero-text-2-emphasis emph-brush accent-1-text">efficiency and productivity </span></h3>,
            subheading: "Manual data management processes are time-consuming and prone to errors. Our data integration solutions automate data workflows, reducing manual intervention and eliminating errors. By streamlining data operations, you can enhance efficiency, improve data quality, and free up valuable resources to focus on strategic initiatives.",
            icon: <AiOutlineLineChart className='icon-large  mb-3' />
        },
        {
            id: 5,
            heading: <h3 className="hero-text-2 white-text">Unlock your <span className="hero-text-2-emphasis secondary-text emph-brush">data potential</span></h3>,
            subheading: "Don't let fragmented data hold your business back. Partner with Mobyte and unlock the full potential of your data with advanced data integration services. Whether you're looking to improve operational efficiency, enhance data quality, or gain deeper insights, we're here to help you succeed.",
            icon: <IoLockOpenOutline className='icon-large  mb-3 secondary-text' />
        }
        ]
    },
    {
        id: "softwaremodernisation",
        icon: <IoLogoReact size={100} color={iconColor} />,
        heading: "Software modernisation",
        subHeading:
            <span >
                <span className="hero-text-1 white-text">Software modernisation</span>&nbsp;&nbsp;
                {/* that empower your business */}
            </span>
        ,
        description: "Updating or transforming legacy software systems to improve their functionality, performance, and security. The aim is to bring systems up to date with current technology, enabling them to better meet the evolving needs of the business.",
        keywords: "Software Modernisation, Legacy Software Modernization, Software Upgrade Services, Modernization of Software Systems, Legacy System Transformation, Software Enhancement, Legacy System Upgrade, Software System Modernization, Legacy Application Modernization, Software Performance Improvement, Modernizing Legacy Systems, Software Security Enhancement, Legacy Software Update, Software Modernization Solutions, Software Modernization Services, Updating Legacy Software, Transforming Legacy Software, Enhancing Software Functionality, Software Modernization Strategies, Improving Software Performance",
        content: [{
            id: 1,
            heading: <h3 className="hero-text-2 secondary-text"><span className="hero-text-2-emphasis accent-1-text emph-brush">Transform</span> your legacy systems</h3>,
            subheading: "In an era of rapid technological advancement, relying on outdated software can impede your business's growth and agility. At Mobyte, we specialize in transforming legacy systems into modern, efficient, and scalable solutions that drive innovation and success.",
            icon: <IoArrowUpSharp className='icon-large icon-right mb-3 ' />
        },
        {
            id: 2,
            heading: <h3 className="hero-text-2 white-text"><span className="hero-text-2-emphasis emph-brush">Future proof</span> your business</h3>,
            subheading: "Technology is constantly evolving, and your software should keep pace. Our modernization solutions are designed with the future in mind, ensuring that your software remains relevant and adaptable to emerging trends and market demands. By embracing modern technologies, you can future-proof your business and stay ahead of the competition.",
            icon: <AiOutlineApi className='icon-large  mb-3 ' />
        },
        {
            id: 3,
            heading: <h3 className="hero-text-2 white-text">Enhance <span className="hero-text-2-emphasis accent-1-text emph-brush">performance and scalability</span></h3>,
            subheading: "Outdated software can struggle to meet the performance demands of today's fast-paced business environment. We enhance the performance and scalability of your applications, ensuring they can handle increased workloads and deliver a seamless user experience. Our modernization efforts include optimizing code, improving database performance, and leveraging cloud technologies to boost efficiency and scalability.",
            icon: <AiOutlineDatabase className='icon-large icon-right mb-3 white-text' />
        },
        {
            id: 4,
            heading: <h3 className="hero-text-2 ">Improve <span className="hero-text-2-emphasis emph-brush accent-1-text">security and compliance</span></h3>,
            subheading: "Legacy systems are often more vulnerable to security threats and compliance issues. Our software modernization services address these concerns by incorporating robust security measures and ensuring compliance with industry standards and regulations. We help you safeguard your data and protect your business from potential risks.",
            icon: <IoShieldCheckmarkOutline className='icon-large  mb-3' />
        },
        {
            id: 5,
            heading: <h3 className="hero-text-2 white-text">Seemless <span className="hero-text-2-emphasis secondary-text emph-brush">Transition</span></h3>,
            subheading: "Transitioning from legacy systems to modern solutions can be challenging. Our experienced team ensures a smooth and seamless transition with minimal disruption to your operations. We employ best practices and proven methodologies to migrate your data, applications, and processes, minimizing downtime and ensuring business continuity.",
            icon: <IoSwapHorizontalOutline className='icon-large  mb-3 secondary-text' />

        },
        {
            id: 6,
            heading: <h3 className="hero-text-2 white-text">Unlock <span className="hero-text-2-emphasis accent-1-text emph-brush">innovation and efficiency</span></h3>,
            subheading: "Modernizing your software opens up new possibilities for innovation and efficiency. By integrating advanced features, automating manual processes, and leveraging data analytics, we help you unlock new opportunities for growth and improvement. Our modernization solutions empower you to innovate, streamline operations, and achieve your strategic goals.",
            icon: <IoLockOpenOutline className='icon-large  mb-3 white-text' />
        }
        ]
    },
    {
        id: "databasemanagement",
        icon: <AiOutlineDatabase size={100} color={iconColor} />,
        heading: "Database management",
        subHeading:
            <span >
                <span className="hero-text-1 white-text">Database management</span>&nbsp;&nbsp;
                {/* that empower your business */}
            </span>
        ,
        content: [{
            id: 1,
            heading: <h3 className="hero-text-2 secondary-text"><span className="hero-text-2-emphasis accent-1-text emph-brush">Optimize</span> your data</h3>,
            subheading: "In the digital age, effective database management is crucial for maintaining the integrity, performance, and security of your data. At Mobyte, we provide comprehensive database management services designed to optimize your data infrastructure and support your business objectives.",
            icon: <IoArrowUpSharp className='icon-large icon-right mb-3 ' />
        },
        {
            id: 2,
            heading: <h3 className="hero-text-2 white-text">Customized <span className="hero-text-2-emphasis emph-brush">database solutions</span> for your needs</h3>,
            subheading: "Every business has unique data requirements. Our team of experienced database administrators works closely with you to understand your specific needs and challenges. We design and implement customized database solutions that align with your business goals, ensuring efficient data storage, retrieval, and management.",
            icon: <AiOutlineApi className='icon-large  mb-3 ' />
        },
        {
            id: 3,
            heading: <h3 className="hero-text-2 white-text">Enhance <span className="hero-text-2-emphasis accent-1-text emph-brush">performance and scalability</span></h3>,
            subheading: "As your business grows, so does your data. Our database management services ensure your databases are optimized for performance and scalability. We fine-tune database configurations, optimize queries, and implement best practices to handle increasing data volumes and user loads. By enhancing database performance, we help you deliver fast and reliable data access to your applications and users.",
            icon: <AiOutlineDatabase className='icon-large icon-right mb-3 white-text' />
        },
        {
            id: 4,
            heading: <h3 className="hero-text-2 ">Improve <span className="hero-text-2-emphasis emph-brush accent-1-text">data security and compliance</span></h3>,
            subheading: "Protecting your data from unauthorized access and breaches is a top priority. Our database management solutions include robust security measures to safeguard your data. We implement encryption, access controls, and regular security audits to ensure your databases comply with industry standards and regulations. With our proactive approach, you can trust that your data is secure and compliant.",
            icon: <IoShieldCheckmarkOutline className='icon-large  mb-3' />
        },
        {
            id: 5,
            heading: <h3 className="hero-text-2 white-text"><span className="hero-text-2-emphasis secondary-text emph-brush">Backup and Recovery</span></h3>,
            subheading: "Data loss can have devastating consequences for your business. We provide comprehensive backup and recovery solutions to ensure your data is always protected. Our services include regular automated backups, disaster recovery planning, and rapid data restoration in the event of a failure. With our backup and recovery solutions, you can minimize downtime and ensure business continuity.",
            icon: <IoSaveOutline className='icon-large  mb-3 secondary-text' />

        },
        {
            id: 6,
            heading: <h3 className="hero-text-2 white-text">Unlock the <span className="hero-text-2-emphasis accent-1-text emph-brush">power of your data</span></h3>,
            subheading: "Don't let database challenges hold your business back. Partner with Mobyte and benefit from our expert database management services. Whether you're looking to optimize performance, enhance security, or ensure reliable backups, we're here to support you every step of the way.",
            icon: <IoLockOpenOutline className='icon-large  mb-3 white-text' />
        }
        ],
        description: "Collecting, storing, organizing, maintaining, and utilizing data to meet the needs of an organization. Effective data management is crucial for businesses to make informed decisions, improve efficiency, and remain competitive.",
        keywords: "Database Management, Data Management Services, Database Administration, Database Solutions, Data Management Solutions, Database Management Company, Database Maintenance, Data Storage Solutions, Database Optimization, Database Management System, Database Security, Database Performance Tuning, Data Backup and Recovery, Data Governance, Database Management Best Practices, Cloud Database Management, Database Integration, Enterprise Data Management, Data Management Strategies, Database Management Services for Businesses"
    },
    {
        id: "automation",
        icon: <AiOutlineSetting size={100} color={iconColor} />,
        heading: "Automation",
        subHeading:
            <span >
                <span className="hero-text-1 white-text">Automation</span>&nbsp;&nbsp;
                {/* that empower your business */}
            </span>
        ,
        content: [{
            id: 1,
            heading: <h3 className="hero-text-2 secondary-text"><span className="hero-text-2-emphasis accent-1-text emph-brush">Revolutionize</span> your operations</h3>,
            subheading: "In today's fast-paced business environment, efficiency and productivity are key to staying competitive. At Mobyte, we specialize in delivering cutting-edge automation solutions that streamline your operations, reduce manual effort, and drive significant cost savings.",
            icon: <AiOutlineSetting className='icon-large icon-right mb-3 ' />
        },
        {
            id: 2,
            heading: <h3 className="hero-text-2 white-text"><span className="hero-text-2-emphasis emph-brush">Tailored automation</span> for your unique processes</h3>,
            subheading: "Every business has distinct processes and workflows. Our team of automation experts works closely with you to understand your specific needs and identify opportunities for automation. We design and implement custom automation solutions that integrate seamlessly with your existing systems, enhancing efficiency and consistency across your operations.",
            icon: <AiOutlineCode className='icon-large  mb-3 ' />
        },
        {
            id: 3,
            heading: <h3 className="hero-text-2 white-text">Boost <span className="hero-text-2-emphasis accent-1-text emph-brush">productivity and efficiency</span></h3>,
            subheading: "Manual tasks can be time-consuming and prone to errors. Our automation solutions eliminate repetitive tasks, freeing up your employees to focus on more strategic and value-added activities. By automating routine processes, we help you boost productivity, reduce operational costs, and improve overall efficiency.",
            icon: <AiOutlineLineChart className='icon-large icon-right mb-3 white-text' />
        },
        {
            id: 4,
            heading: <h3 className="hero-text-2 "><span className="hero-text-2-emphasis emph-brush accent-1-text">Scalable solutions</span> for growth</h3>,
            subheading: "As your business grows, so do your operational demands. Our automation solutions are designed to scale with your business, allowing you to easily adapt to increasing workloads and evolving requirements. Whether you're expanding your operations or launching new initiatives, our scalable automation solutions provide the flexibility you need to support growth.",
            icon: <IoShieldCheckmarkOutline className='icon-large  mb-3' />
        },
        {
            id: 5,
            heading: <h3 className="hero-text-2 white-text"><span className="hero-text-2-emphasis secondary-text emph-brush">Integration and support</span></h3>,
            subheading: "Implementing automation can be complex, but our team is here to ensure a smooth transition. We provide comprehensive support throughout the entire process, from initial consultation to deployment and beyond. Our solutions integrate seamlessly with your existing systems, and we offer ongoing maintenance and support to keep your automation running smoothly.",
            icon: <IoChatbubbleEllipsesOutline className='icon-large  mb-3 secondary-text' />

        },
        {
            id: 6,
            heading: <h3 className="hero-text-2 white-text">Unlock your <span className="hero-text-2-emphasis accent-1-text emph-brush">business potential</span> with automation</h3>,
            subheading: "Automation is more than just a tool—it's a strategic advantage. By automating your processes, you can achieve faster turnaround times, improved accuracy, and greater operational efficiency. Our advanced automation solutions empower you to transform your business and stay ahead in a competitive market.",
            icon: <IoLockOpenOutline className='icon-large  mb-3 white-text' />
        }
        ],
        description: "The process of turning repeatable, routine tasks into automated actions. We work with businesses to use technology to perform processes with little or no human intervention. This can improve efficiency, accuracy, and consistency.",
        keywords: "Automation, Business Process Automation, Workflow Automation, Automation Services, Process Automation, Automation Solutions, Robotic Process Automation, Automation Company, Automation Tools, Automation Technologies, Automated Systems, Business Automation Solutions, Custom Automation Services, Industrial Automation, IT Automation, Office Automation, Automation for Businesses, Automation Software, Automation and Efficiency, Automation Strategies"
    },
];

export default servicesData;
