import { Button, Modal, ModalFooter, ModalBody } from "reactstrap";

const ConfirmToast = (props) => {
    const defaultClassName =
        "toast-popup-dim modal-dialog-centered notransform modal-dialog";

    const customClassName = props.className
        ? `${defaultClassName} ${props.className}`
        : defaultClassName;
    return (
        <>
            {props.show && (
                <div>
                    <Modal
                        wrapClassName="deleteToast"
                        className={customClassName}
                        backdrop="static"
                        fade={false}
                        isOpen
                        size="md"
                        keyboard={false}
                    >
                        <ModalBody
                            className="text-center"
                            style={{ borderBottom: "1px solid #68686740" }}
                        >
                            <div>
                                <p
                                    className="card-text breadCrumbFont"
                                    style={{ fontSize: "12px" }}
                                >
                                    {props.Message}
                                </p>
                            </div>
                        </ModalBody>

                        <ModalFooter style={{ alignSelf: "center", borderTop: "none" }}>

                            <Button
                                onClick={props.handleYes}
                                color="success" size="sm" className="btn-text b-width"

                                value="Yes"
                                id="Yes"
                            >
                                Yes
                            </Button>
                            <Button
                                onClick={props.handleNo}
                                color="secondary" size="sm" className="btn-text b-width"
                                value="No"
                                id="No"
                            >
                                No
                            </Button>
                        </ModalFooter>
                    </Modal>

                    {/* </div>
            </div> */}
                </div>
            )}
        </>
    );
};

export default ConfirmToast;
