import React from 'react'
import { Row, Col } from 'reactstrap'
import { IoCheckmarkSharp } from "react-icons/io5";


export const HireSection = () => {

    return (
        <div className="container-large pt-5 pb-5">
            <Row >
                <Col xl="6" className="d-flex align-items-center">
                    <Row>
                        <Col xs="12">
                            <div className="hero-text-1 hire-section-text">
                                Curious about your IT potential?
                                <br />
                                Discover it with us today
                            </div>
                        </Col>
                        <Col xs="12">
                            <div>
                                <a href="https://outlook.office365.com/book/Mobyte1@MobyteLtd.onmicrosoft.com/" target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "black" }} >
                                    <button className="cta-button mt-5" >
                                        Schedule free call
                                    </button>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xl="6" className="hero-text-2 hire-section-bullets pt-5 pb-5 ps-5 pe-5" >
                    <div>
                        < IoCheckmarkSharp size={30} className="hire-section-ticks" /> &nbsp; <span className="large-text text-white">Custom software</span>
                    </div>
                    <div >
                        < IoCheckmarkSharp size={30} className="hire-section-ticks" /> &nbsp; <span className="large-text text-white">Scalable IT solutions</span>
                    </div>
                    <div>
                        < IoCheckmarkSharp size={30} className="hire-section-ticks" /> &nbsp; <span className="large-text text-white">Modern technologies</span>
                    </div>
                    <div>
                        < IoCheckmarkSharp size={30} className="hire-section-ticks" /> &nbsp; <span className="large-text text-white">Flexible process</span>
                    </div>
                    <div>
                        < IoCheckmarkSharp size={30} className="hire-section-ticks" /> &nbsp; <span className="large-text text-white">Personalised consultations</span>
                    </div>
                </Col >
            </Row >

        </div >

    );
}
export default HireSection